import React, { useEffect } from 'react';
import styles from './errorPage.module.css';
import {Button, Container} from 'unify-react';
import { useNavigate } from 'react-router-dom';

function ErrorPage(props){
    const navigate = useNavigate();
    const BackHome = () =>{
        props.setErrorCode(null);
        navigate('/');
    };

    useEffect(() => {
        props.setWrapperClass('error-page');

       return function cleanup () {
            props.setWrapperClass('');
       };
    }, []);

    return (
        <div className={styles.ErrorPage + " "+ styles.error_background}>
            <div className={styles.content}>
                <h1>404</h1>
                <h3>Oops!! We can't find the page you're looking for.</h3>
                <p>Either the page doesn't exists or is unavailable. Let's try this again!</p>
                <Button className="ml-2 btn-md" color="primary" onClick={BackHome}>Back Home</Button>
            </div>
        </div>
    );
}

export default ErrorPage;