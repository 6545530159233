import React, { useState, useEffect } from "react";
import { API_ROOT } from "../ApiConfig/apiConfig";
import JwtDecode from "jwt-decode";
import _ from "lodash";
import { getUserFdic } from "../Shared/Utilities/userUtils";
import axios from "axios";
import { LoadingSpinner } from "@deluxe/unify-loading-spinner";

export const UserContext = React.createContext({
  user: null,
});

const UserContextProvider = (props) => {
  const bankersSession = JSON.parse(sessionStorage.getItem("bankersSession"));
  if (bankersSession) {
    window.token = bankersSession.token;
    window.bankId = bankersSession.contextEntity;
    const decodedToken = JwtDecode(window.token);
    window.username = decodedToken.unique_name;
    window.fdic = getUserFdic(window.username);
  }
  const apiOptions = { headers: { Authorization: `Bearer ${window.token}` } };
  const [userProfile, setUserProfile] = useState(null);
  const [messageReceived, setMessageRecieved] = useState(false);

  const getEntitiesList = async () => {
    let entitiesList = await axios.get(
      `${API_ROOT.fdicEndpoint}/api/Fdic/${window.fdic}/entities-list`,
      apiOptions
    );
    return entitiesList.data;
  };

  const mapUser = (user, entitiesList) => {
    let userCopy = _.cloneDeep(user);
    userCopy = setupUserEntities(userCopy, entitiesList);
    return userCopy;
  };

  const setupUserEntities = (userCopy, entitiesList) => {
    userCopy.entities.forEach((entity) => {
      let entResult = entitiesList.find(
        (entResult) => entResult.id === entity.bankId
      );
      entity.name = entResult.name;
      entity.listOrder = entResult.listOrder;
    });
    userCopy.entities = userCopy.entities.sort(
      (a, b) => a.listOrder - b.listOrder
    );
    return userCopy;
  };

  const getUserProfile = async () => {
    let result = await axios.get(
      `${API_ROOT.userEndpoint}/api/${window.fdic}/users/profile`,
      apiOptions
    );
    let entities = window.fdic && (await getEntitiesList());
    setUserProfile(entities ? mapUser(result.data, entities) : result.data);
  };

  const handleBankersSession = (event) => {
    if (!bankersSession && event.origin === API_ROOT.clientEndpoint) {
      sessionStorage.setItem("bankersSession", JSON.stringify(event.data));
      setMessageRecieved(true);
    }
  };
  useEffect(() => {
    if (!bankersSession && window.opener) {
      window.onload = function () {
        window.opener.postMessage("loaded", API_ROOT.clientEndpoint);
      };
    }
    window.addEventListener("message", handleBankersSession);
    return () => {
      window.removeEventListener("message", handleBankersSession);
    };
  });
  useEffect(() => {
    if (window.token && !userProfile) {
      getUserProfile();
    }
  });
  if (userProfile) {
    return (
      <>
        <UserContext.Provider value={{ user: userProfile }}>
          {props.children}
        </UserContext.Provider>
      </>
    );
  } else {
    return <LoadingSpinner isActive={!userProfile} />;
  }
};

export default UserContextProvider;
