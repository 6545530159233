import React, { useState, useEffect } from "react";
import { UnifyIcon } from "@deluxe/unify-icon";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Row, Col, Button } from "unify-react";
import { LoadingSpinner } from "@deluxe/unify-loading-spinner";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faGlobeAmericas,
  faPaste,
  faPencilAlt,
} from "@fortawesome/free-solid-svg-icons";
import { API_ROOT } from "../ApiConfig/apiConfig";
import ProjectionFormModal from "./projectionFormModal";
import ConfirmModal from "../Shared/Components/confirmModal";
import axios from "axios";
import { useNavigate } from "react-router-dom";

export function getTitle(opt) {
  return "Projections - " + opt;
}

function Projections(props) {
  let token = window.token;
  const navigate = useNavigate();
  const EDIT_CELL_INDEX = 5;
  const COPY_CELL_INDEX = 6;
  const DELETE_CELL_INDEX = 7;

  const [data, setData] = useState({
    isLoading: true,
    projects: [],
    bankId: null,
  });

  const [showModal, setShowModal] = useState(false);

  const [showFormModal, setShowFormModal] = useState(false);
  const [isEditable, setIsEditable] = useState(true);
  const username = window.username;
  const [isDeleted, setIsDeleted] = useState(false);
  const [projectData, setProjectData] = useState({
    projectionName: "",
    editDisabled: false,
  });
  const [modalType, setModalType] = useState("create");
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedProjectId, setSelectedProjectId] = useState(null);
  const projectionEndpoint = `${API_ROOT.projectionEndpoint}/api/projections/${data.bankId}/project`;
  const apiOptions = { headers: { Authorization: `Bearer ${token}` } };

  const projectColumns = [
    {
      field: "id",
      header: "Project ID",
      hidden: true,
    },
    {
      field: "description",
      header: "Name",
      headerClassName: "projections-column-header",
      bodyClassName: "projections-column",
    },
    {
      field: "startYear",
      header: "Start Year",
      headerClassName: "projections-column-header-medium",
      bodyClassName: "projections-column-medium",
    },
    {
      field: "numberOfYears",
      header: "Years",
      headerClassName: "projections-column-header-medium",
      bodyClassName: "projections-column-medium",
    },
    {
      field: "isPublic",
      header: "Public",
      headerClassName: "projections-column-header-small",
      bodyClassName: "projections-column-small",
      body: (row) => {
        if (row.isPublic) {
          return <FontAwesomeIcon icon={faGlobeAmericas} />;
        }
      },
    },
    {
      header: "Edit",
      body: () => {
        return (
         <FontAwesomeIcon  icon={faPencilAlt} style={{ color: "#000000" }} title="projection-edit"/>
        );
      },
      headerClassName: "w-15rem",
    },
    {
      header: "Copy",
      body: () => {
        return <FontAwesomeIcon icon={faPaste} title="projection-copy" />;
      },
      headerClassName: "w-15rem",
    },
    {
      header: "Delete",
      body: () => {
        return (
          <span data-testid="projection-delete"><UnifyIcon  color="red" iconName="delete" width="1.4" height="1.4"/></span>
        );
      },
      headerClassName: "w-15rem",
    },
  ];
  const openModal = () => {
    setShowModal(true);
  };
  const closeModal = () => {
    setShowModal(false);
  };

  const openFormModal = () => {
    setShowFormModal(true);
  };
  const closeFormModal = () => {
    setShowFormModal(false);
  };
  const openEditModal = (event) => {
    setModalType("Edit");
    openFormModal();
  };

  const openCopyModal = (event) => {
    setModalType("Copy");
    openFormModal();
  };

  const openDeleteModal = () => {
    setShowDeleteModal(true);
  };

  const deleteProjection = async (projectId) => {
    setShowDeleteModal(false);
    await axios
      .delete(`${projectionEndpoint}/${projectId}`, apiOptions)
      .then(() => {
        setSelectedProjectId(null);
        setIsDeleted(true);
      })
      .catch((error) => {});
  };

  const cellClicked = (event) => {
    if (event.cellIndex <= 4) {
      navigate(`/projection/${data.bankId}/${event.rowData.id}/data`);
    } else {
      setSelectedProjectId(event.rowData.id);
      if (event.cellIndex === EDIT_CELL_INDEX) {
        openEditModal();
      } else if (event.cellIndex === COPY_CELL_INDEX) {
        openCopyModal();
      } else if (event.cellIndex === DELETE_CELL_INDEX) {
        openDeleteModal();
      }
    }
  };

  useEffect(() => {
    if(isDeleted){
      setIsDeleted(false);
    }
    let bankId = window.bankId;
    const fetchForecastData = async () => {
      try {
        const result = await axios.get(
          `${API_ROOT.projectionEndpoint}/api/projections`,
          { headers: { Authorization: `Bearer ${token}` } }
        );
        setData({ isLoading: false, projects: result.data, bankId: bankId });
      } catch (error) {}
    };
    fetchForecastData();
  }, [isDeleted]);

  return (
    <>
      {data.isLoading ? (
        <LoadingSpinner isActive={data.isLoading} />
      ) : (
        <>
          <ConfirmModal
            title="Delete Projection"
            body="Are you sure you want to delete this projection?"
            buttonColor="danger"
            buttonLabel="Delete"
            closeLabel="Close"
            show={showDeleteModal}
            handleClose={() => setShowDeleteModal(false)}
            handleConfirm={() => deleteProjection(selectedProjectId)}
          />
          <ProjectionFormModal
            formType="Create"
            handleClose={closeModal}
            bankId={data.bankId}
            show={showModal}
          />
          <ProjectionFormModal
            formType={modalType}
            handleClose={closeFormModal}
            projectId={selectedProjectId}
            bankId={data.bankId}
            show={showFormModal}
            setIsEditable={setIsEditable}
          />
          <Row className="no-gutters table-header">
            <div>
            <h1 className="app-title-h1">Projections</h1>
            <Button color="secondary" size="sm" onClick={openModal}>
              <span>
                <i className="fas fa-plus-circle"></i>&nbsp;Create
                Projection
              </span>
            </Button>
              </div>
          </Row>
          <Row className="no-gutters">
            <DataTable
              tableClassName="projections-table"
              dataKey="id"
              value={data.projects}
              cellSelection
              selectionMode="single"
              onCellClick={cellClicked}
              showGridlines
              rowHover
              emptyMessage="Project list is empty"
              scrollable
              scrollHeight='720px'
            >
              {projectColumns.map((col, i) => (
                <Column
                  key={col.field}
                  field={col.field}
                  header={col.header}
                  hidden={col.hidden}
                  headerClassName={col.headerClassName}
                  bodyClassName={col.bodyClassName}
                  body={col.body}
                />
              ))}
            </DataTable>
          </Row>
        </>
      )}
    </>
  );
}

export default Projections;
