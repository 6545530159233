import React from 'react';
import { Panel, PanelBody, PanelFooter, Container, Button, FontAwesomeIcon } from 'unify-react';
import { Col, Row } from 'unify-react';
import cardStyles from '../Users/userCard.module.css';
import peerGroupCardStyles from './peerGroupCard.module.css';
import dayjs from 'dayjs';

function PeerGroupCard (props) {
    const peerGroup = props;
    const lastUpdatedAt = dayjs(peerGroup.updated_at).isValid()
     ? dayjs(peerGroup.updated_at).format("MM/DD/YYYY - hh:mma") : '';

    const handleEditPeerGroup = () => {
        peerGroup.onEdit();
    }

    const handleStatusChange = () => {
        peerGroup.onStatusChange();
    }

    return (
        <Col xs="4" className="peer-group-card">
            <Panel className={cardStyles.user_card}>
                <PanelBody onClick={handleEditPeerGroup}>
                    <Container className="peer-group-manage">
                        <Row><h6>{peerGroup.title}</h6></Row>
                        <Row><a className="financial-institutions">{peerGroup.institutions}</a></Row>
                        <Row className="margin-top-15"><span className={peerGroupCardStyles.peer_group_description}>{peerGroup.text}</span></Row>
                        <Row className="margin-top-25 margin-bottom-10">
                            <Col xs="6">
                                <Row>
                                    <span className={`${cardStyles.user_card_label}`}>Last Update</span>
                                </Row>
                                <Row>
                                    <span className={cardStyles.user_card_subheading}>{lastUpdatedAt ? lastUpdatedAt : 'N/A'}</span>
                                </Row>
                            </Col>
                            <Col xs="5" className="last-updated">
                                <Row>
                                    <span className={`${cardStyles.user_card_label}`}>Last updated by</span>
                                </Row>
                                <Row>
                                    <span className={cardStyles.user_card_subheading}>{peerGroup.updated_by ? peerGroup.updated_by : 'N/A'}</span>
                                </Row>
                            </Col>
                        </Row>
                    </Container>
                </PanelBody>
                <hr className={cardStyles.user_card_divider} />
                <PanelFooter className={cardStyles.user_card_footer}>
                    <Container>
                        <Row>
                            <Col className="no-side-padding" xs="12">
                                <Button className={cardStyles.user_card_footer_btn} color="secondary" onClick={peerGroup.onDelete}>
                                    <FontAwesomeIcon icon={["far", "trash-alt"]} size="1x" />
                                    <span>Delete</span>
                                </Button>
                            </Col>
                        </Row>
                    </Container>
                </PanelFooter>
            </Panel>
        </Col>
    );
}

export default PeerGroupCard;