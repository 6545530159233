import React from "react";
import Backdrop from "../Report/backdrop";
import "./sideDrawer.css";
import { UnifyCard } from "@deluxe/unify-card";
import { UnifyIcon } from "@deluxe/unify-icon";

function SideDrawer({show, sideDrawerClosed, toggleSideDrawer, children, refreshClicked, headerTitle}) {
  let drawerClasses = "side-drawer";
  if (show) {
    drawerClasses = "side-drawer open";
  }
  return (
    <React.Fragment>
      <Backdrop show={show} clicked={sideDrawerClosed} />
      <div className={drawerClasses}>
        <div className="side-drawer-items">
          <div className="side-drawer-toggle">
            <div data-testid="sd-toggle" onClick={toggleSideDrawer}>
                <UnifyIcon
                    color="red"
                    iconName="dropdown-chevron"
                    width="1.5"
                    height="1.5"
                    wrapperClasses="side-drawer-toggle-icon"
                />
            </div>
          </div>
         {show && <div className="card-item">
            <UnifyCard
              headerTitle={headerTitle}
              bodyContent={children}
              headerFilter=<span data-testid="sd-refresh" onClick={refreshClicked}>
                <UnifyIcon
                  color="red"
                  iconName="refresh"
                  width="2"
                  height="2"
                />
              </span>
            />
          </div>}
        </div>
      </div>
    </React.Fragment>
  );
}

export default SideDrawer;
