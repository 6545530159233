import React, { useState, useEffect } from "react";
import { useNavigate, Link } from "react-router-dom";
import { Row, Col, Container, Input } from "unify-react";
import { LoadingSpinner } from "@deluxe/unify-loading-spinner";
import { API_ROOT } from "../ApiConfig/apiConfig";
import UserCard from "./userCard";
import styles from "./users.module.css";
import axios from "axios";
import ConfirmModal from "../Shared/Components/confirmModal";

function Users(props) {
  let navigate = useNavigate();
  const [data, setData] = useState({ isLoading: true, users: [], count: 0 });
  const [userList, setUserList] = useState({ users: [] });
  const fdic = window.fdic;
  const apiOptions = { headers: { Authorization: `Bearer ${window.token}` } };
  const [selectedUser, setSelectedUser] = useState(0);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showActivateUserModal, setShowActivateUserModal] = useState(false);
  const [showDeactivateUserModal, setShowDeactivateUserModal] = useState(false);
  const [showUserModal, setShowUserModal] = useState(false);
  const [btnVisibility, setBtnVisibility] = useState("");

  const fetchUsers = async () => {
    let fdic = window.fdic;
    let token = window.token;
    try {
      const result = await axios.get(
        `${API_ROOT.userEndpoint}/api/${fdic}/users`,
        apiOptions
      );
      setData({
        isLoading: false,
        users: result.data,
        count: result.data.length,
      });
      setUserList({ users: result.data });
    } catch (error) {}
  };

  const checkIsSSO = () => {
    isSSO(window.username)
      .then((result) => {
        let isSSO = result.data;
        if (isSSO) {
          setBtnVisibility(styles.hideButton);
        }
      })
      .catch((e) => {
        console.log("error getting SSO user");
      });
  };

  const isSSO = async (username) => {
    try {
      return await axios.get(
        `${API_ROOT.clientEndpoint}/api/users/${username}/sso`,
        apiOptions
      );
    } catch (e) {}
  };

  const openDeleteModal = (userId) => {
    setSelectedUser(userId);
    setShowDeleteModal(true);
  };

  const filterUsers = (event) => {
    let searchValue = event.target.value;
    if (searchValue === "") {
      setData({
        isLoading: false,
        users: userList.users,
        count: userList.length,
      });
    } else {
      searchValue = searchValue.toLowerCase();
      let filteredUsers = userList.users.filter(
        (user) =>
          user.firstName.toLowerCase().includes(searchValue) ||
          user.lastName.toLowerCase().includes(searchValue) ||
          user.username.toLowerCase().includes(searchValue) ||
          user.name.toLowerCase().includes(searchValue)
      );
      setData({
        isLoading: false,
        users: filteredUsers,
        count: filteredUsers.length,
      });
    }
  };

  const editUser = (userId) => {
    navigate(`/user/${userId}`);
  };

  const deleteUser = async () => {
    setShowDeleteModal(false);
    await axios
      .delete(
        `${API_ROOT.userEndpoint}/api/${fdic}/users/${selectedUser}`,
        apiOptions
      )
      .then(() => {
        fetchUsers();
      })
      .catch((error) => {});
  };

  const openStatusModal = (userId) => {
    let toggle = document.getElementById(`status-${userId}`);
    setSelectedUser(userId);
    if (toggle.checked) {
      setShowActivateUserModal(true);
    } else {
      setShowDeactivateUserModal(true);
    }
  };

  const closeStatusModal = () => {
    let toggle = document.getElementById(`status-${selectedUser}`);
    let user = data.users.find(({ id }) => id === selectedUser);
    toggle.checked = user.enabled;
    setShowActivateUserModal(false);
    setShowDeactivateUserModal(false);
  };

  const updateUserStatus = async (active) => {
    let toggle = document.getElementById(`status-${selectedUser}`);
    await axios
      .put(
        `${API_ROOT.userEndpoint}/api/${fdic}/users/${selectedUser}/status?enabled=${active}`,
        "",
        apiOptions
      )
      .then(() => {
        setShowActivateUserModal(false);
        setShowDeactivateUserModal(false);
        fetchUsers();
      })
      .catch((error) => {
        setShowActivateUserModal(false);
        setShowDeactivateUserModal(false);
      });
  };

  useEffect(() => {
    fetchUsers();
    checkIsSSO();
  }, []);

  return (
    <>
      {data.isLoading ? (
        <LoadingSpinner isActive={data.isLoading} />
      ) : (
        <>
          <Container
            className={`${styles.fixed_container} ${styles.disable_scrollbars}`}
          >
            <ConfirmModal
              title="Delete User"
              body="Are you sure you want to delete this user?"
              buttonLabel="Yes"
              closeLabel="Cancel"
              show={showDeleteModal}
              handleClose={() => setShowDeleteModal(false)}
              handleConfirm={() => deleteUser()}
            />
            <ConfirmModal
              title="Activate User"
              body="Are you sure you want to activate this user?"
              buttonLabel="Yes"
              closeLabel="Cancel"
              show={showActivateUserModal}
              handleClose={() => closeStatusModal()}
              handleConfirm={() => updateUserStatus(true)}
            />
            <ConfirmModal
              title="Deactivate User"
              body="Are you sure you want to deactivate this user?"
              buttonLabel="Yes"
              closeLabel="Cancel"
              show={showDeactivateUserModal}
              handleClose={() => closeStatusModal()}
              handleConfirm={() => updateUserStatus(false)}
            />
            <div className={styles.fixed_header}>
              <Container>
                <Row className="no-gutters table-header" xs="1" sm="2">
                  <Col>
                    <h1 className="app-title-h1">Manage Users</h1>
                    <h6>Add, Delete, Modify Users or View user usage</h6>
                  </Col>
                </Row>
                <Row className={`${styles.users_vert_centered} no-gutters`}>
                  <Col xs="6">
                    <Row>
                      <Col xs="8">
                        <Input
                          className={styles.input_no_border}
                          type="text"
                          name="search"
                          id="user-search"
                          placeholder="Search for User"
                          onChange={filterUsers}
                        />
                      </Col>
                      <Col xs="4">
                        <span
                          className={`${styles.users_vert_centered} ${styles.users_result}`}
                        >
                          Results: {data.users.length}
                        </span>
                      </Col>
                    </Row>
                  </Col>
                  <Col xs="6" className="d-flex justify-content-end">
                    {
                      <div className={`mt-auto ${btnVisibility}`}>
                        <Link
                          className={`${styles.add_user_btn} btn btn-primary`}
                          to="/user"
                          role="button"
                        >
                          Add User
                        </Link>
                      </div>
                    }
                  </Col>
                </Row>
              </Container>
            </div>
            <Container
              className={`${styles.fixed_content} ${styles.disable_scrollbars}`}
            >
              <Row>
                {data.users.map((user) => (
                  <UserCard
                    key={user.id}
                    {...user}
                    onEdit={() => editUser(user.id)}
                    onDelete={() => openDeleteModal(user.id)}
                    onStatusChange={() => openStatusModal(user.id)}
                  />
                ))}
              </Row>
            </Container>
          </Container>
        </>
      )}
    </>
  );
}

export default Users;