import React, { useState, useRef } from "react";
import { TabView, TabPanel } from 'primereact/tabview';
import { Button } from 'primereact/button';
import "./tabManager.css";

import { 
    useLocation,
    useMatches,
    useOutlet,
  } from "react-router-dom";

// The TabManager component behaves similarly to 
function TabManager({home, homeTitle}) {
    const location = useLocation();
    const matches = useMatches();
    const outlet = useOutlet();
    const componentCounter = useRef(1);
    const Home = (home) ? home : (p) => {};
    const [title] = useState(((homeTitle) ? homeTitle(componentCounter.current) : defaultTabTitle(componentCounter.current)));
    const [children, setChildren] = useState([
        (<TabPanel header={title} ><Home/></TabPanel>)
    ]);
    const [activeIndex, setActiveIndex] = useState(0);
    const childrenRef = useRef(children);

    function newTabHeaderTemplate() {
        return (<Button icon="pi pi-plus" label="+" onClick={addBlankTab} link/>);
    }

    function addBlankTab() {
        addTab({component: "p", getTitle: defaultNewTabTitle}, {id: componentCounter.current});
    }

    // This function adds a new tab. The first parameter is an object which holds a reference to 
    // the component to place inside the tab panel and a function to create a header label for the tab.
    // The second parameter is the properties to pass to the component.
    function addTab(t, props) {
        componentCounter.current++;
        const NewTab = t.component;
        const title = t.getTitle(props.id);
        // We keep track of the active children array using a ref because the instance managed by useState
        // will often reflect the state of the array when a new tab is instantiated. This will cause
        // behavior on the add black tab action where it is holding a copy of children from when it
        // was created and doesn't update. The reference however does remain current, so create a new
        // state instance from it for rendering then update the ref to the new value.
        const newChildren = [...childrenRef.current];
        newChildren.splice(newChildren.length, 0, (<TabPanel header={title} closable><NewTab {...props}/></TabPanel>))
        setChildren(newChildren);
        childrenRef.current = newChildren;
        setActiveIndex(newChildren.length-1);
    }

    // This method is identical to addTab in calling convention but instead of adding a new tab
    // to the tab view it replaces the currently active tab with the new component.
    function setTab(t, props) {
        const ReplaceTab = t.component;
        const title = t.getTitle(props.id);
        const newChildren = [...childrenRef.current]
        const closable = (activeIndex > 0) ? true : false;
        newChildren.splice([activeIndex], 1, (<TabPanel header={title} closable={closable}><ReplaceTab {...props}/></TabPanel>));
        setChildren(newChildren);
        childrenRef.current = newChildren;
    }

    // Maintain state when the active tab changes.
    function handleTabChange(e) {
        setActiveIndex(e.index);
    }

    // Respond to the onBeforeTabClose event and if the index is 0 block tab deletion. 
    // Otherwise switch the active index to the next tab down and return true.
    function handleBeforeTabClose(e) {
        if(e.index === 0) { 
            return false; 
        } 
        return true;
    }

    // Update state on the closing of a tab.
    function handleTabClose(e) {
        setChildren([...childrenRef.current]);
        return true;
    }

    function defaultTabTitle(id) {
        if (
          location.pathname.includes("projectionlist") ||
          location.pathname.includes("projection")
        )
          return "Projection";
        if (location.pathname.includes("BalanceSheet")) return "Balance Sheet";
        if (location.pathname.includes("IncomeStatement"))
          return "Income Statement";
        if (location.pathname.includes("userlist"))
          return "Users";
        if (location.pathname.includes("user"))
          return "User";
        else return "Home";
      }

      function defaultNewTabTitle(id) {
        if (
          location.pathname.includes("projectionlist") ||
          location.pathname.includes("projection")
        )
          return "Projection";
        if (location.pathname.includes("BalanceSheet")) return "Balance Sheet";
        if (location.pathname.includes("IncomeStatement"))
          return "Income Statement";
        if (location.pathname.includes("userlist")) 
          return "Users";
        if (location.pathname.includes("user")) 
          return "User";
        else return "Untitled Document";
      }

    // Recurse through the outlet's children to find the component we want
    function findMatch(match, obj) {
        // If obj doesn't have expected children, bail out.
        try {
            if(!obj.props.children) return null;
        } catch(ex) {
            console.log(ex);
            return null;
        }
        // If there's children but not match property jump deeper
        if(obj.props.children && !obj.props.match) return findMatch(match, obj.props.children);
        // If the match name in our match is same as the current obj then return the type.
        if(match.pathname === obj.props.match.pathname) {
            return [ obj.props.match.route.element.type, obj.props.match.route.element.props ];
        }
        return findMatch(match, obj.props.children);
    }

    function matchTab() {
        // For this we are using nested/child routes for the items which will go in the tabs
        // For this implementation we only pass a counter number to determine which tab count
        // to place in the title.
        if(outlet != null) {
            // Matches start from the top '/' and work down. We want to scan from the bottom up
            // to find the deepest match
            const m = matches.pop();
            if(m) {
                try {
                    const [c, p] = findMatch(m, outlet)
                    const titleFunction = (childrenRef.current.length >= 1 && activeIndex > 0) ? defaultNewTabTitle : defaultTabTitle;
                    const tab = {
                        component: c,
                        outlet: outlet,
                        getTitle: ((m.handle) ? m.handle : titleFunction)
                    }
                    if(tab.component) {
                        let tabProps = (p) ? {...p, id: componentCounter.current} : {id: componentCounter.current};
                        if(m.params) {
                            tabProps = {...tabProps, ...m.params};
                        }
                        // If there is at least 1 tab and there's not a #new hash then we set into the current tab
                        // When doing that just update the tab title if applicable and set the outlet value on 
                        // the children property.
                        if(location.hash === "#new" || children.length === 0) {
                            addTab(tab, tabProps);
                        } else if(location.hash === "") {
                            setTab(tab, tabProps);
                        }
                    }
                } catch(ex) {
                    console.log("TabManager routing issue");
                    console.log(ex);
                }
            }
        }

    }
    React.useEffect(() => {
        matchTab();
    }, [location]);

        return (
            <div className="tab-manager">
                <TabView id="tv" 
                        activeIndex={activeIndex} 
                        children={children} 
                        scrollable
                        renderActiveOnly={false}
                        onTabChange={handleTabChange} 
                        onBeforeTabClose={handleBeforeTabClose} 
                        onTabClose={handleTabClose}></TabView>
            </div>
        )
}
 export default TabManager;