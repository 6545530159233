import devConfig from "./devConfig.json";
import fisConfig from "./fisConfigProd.json";
import { dashboardApis, partnerApis } from "./dashBoardConfig";
import { BANKERS_DOMAINS } from "./domains";

let apis = {};
const bankersHost = window && window.location && window.location.hostname;

const setupToken = () => {
  const token =
    "insert-token";
  const bankId = 1;

  sessionStorage.setItem(
    "bankersSession",
    JSON.stringify({
      contextEntity: bankId,
      token: token,
    })
  );
};

if (
  bankersHost.includes(BANKERS_DOMAINS.Dashboard) ||
  bankersHost.includes(BANKERS_DOMAINS.FisLowerEnv)
) {
  apis = dashboardApis();
} else if (bankersHost.includes(BANKERS_DOMAINS.Fis)) {
  apis = partnerApis();
} else {
  apis = devConfig.apis;
  //setupToken();
}

export const API_ROOT = apis;
