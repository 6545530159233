export const PERMISSIONS = {
    UserAdmin: 1,
    DocumentValueAdmin: 2,
    TabletAccess: 3,
    ReportCustomizaton: 4
}

export const ACCESS_PRIVILEGES = {
    GeneralLedger: 1,
    LoanAndCds: 2,
    LoanPricing: 3,
    DataExport: 4
}

export const ADMINSTRATIVE_PRIVILEGES = {
    ParamsAdmin: 1,
    DesignBoardPackage: 2,
    ProjectionAdmin: 3,
    MappingAdmin: 4,
    MemoFields: 5
}

export const hasPermission = (user, permission) => {
    if (user && user.permissions) {
        return user.permissions.includes(permission);
    }
    return false;
};

export const hasAdminPrivileges = (user, privilege) => {
    if (user && user.entities) {
        let entity = user.entities.find(e => e.bankId === window.bankId);
        return entity && entity.administrativePrivileges.includes(privilege);
    }
    return false;
};


export const hasAccessPrivileges = (user, privilege) => {
    if (user && user.entities) {
        let entity = user.entities.find(e => e.bankId === window.bankId);
        return entity && entity.accessPrivileges.includes(privilege);
    }
    return false;
};





