import { useEffect, useState } from 'react';
import { UnifyTheme } from 'unify-react';
import { isPartner } from '../Utilities/userUtils.js';
const EthosTheme = require('./Ethos/Theme.js');
const BaseTheme = require('./Base/Theme.js');


export const useTheme = () => {
  const [theme, setTheme] = useState(BaseTheme);
  const [themeLoaded, setThemeLoaded] = useState(false);

  const setMode = mode => {
    setTheme(mode);
  };

  const getFonts = () => {
    const allFonts = theme.fonts;
    return allFonts;
  }

  const changeFavicon = (newFaviconUrl, title) => {
    var link = document.createElement('link'),
       oldLink = document.querySelector("link[rel~='icon']");
    link.id = 'dynamic-favicon';
    link.rel = 'icon';
    link.href = newFaviconUrl;
    if (oldLink) {
      document.head.removeChild(oldLink);
    }
    if (title){
     document.title = title;
    }
    document.head.appendChild(link);
  }
  
  useEffect(() => {
    const loadTheme = async () => {
      let currentTheme = theme;
      if(isPartner()){
        currentTheme = EthosTheme;
          //For some reason with js can't store backtick expression in variable
          let newFaviconUrl = require(`./${currentTheme.name}/favicon.ico`).default;
          await changeFavicon(newFaviconUrl, currentTheme.title);
          setTheme(EthosTheme);
      }
      //For some reason with js can't store backtick expression in variable and
      await import(`./${currentTheme.name}/style.css`);
      setThemeLoaded(true);
    }
    loadTheme();
  }, []);

  return { theme, themeLoaded, setMode, getFonts };
};