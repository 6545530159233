export const BANKERS_DOMAINS = {
    Dashboard: '.bankersdashboard.com',
    FisLowerEnv: 'fisethos',
    Fis : 'financialview-fi'
}

export const SERVICE_DOMAINS = {
    projectionHost : "projectionservice",
    userHost : "userservice",
    fdicHost : "fdicservice",
    clientHost : window.location.host.includes('fisethos') ? 'fisethos' : 'clients',
    ClientHostFis : 'financialview-fi.com',
    serviceHost : "service",
    serviceHostProd: "clientservice",
    glHost : "glservice"
}

