//React imports
import React, { useState, useEffect, useContext } from "react";
import { API_ROOT } from "../../ApiConfig/apiConfig";
import axios from "axios";

//styles and ui component
import { UnifyCheckbox } from "@deluxe/unify-checkbox";
import { UnifyDropdown } from "@deluxe/unify-dropdown";
import styles from "./report.module.css";
import "../../index.css";
import { useParams } from "react-router-dom";
import SideDrawer from "../SideDrawer/sideDrawer";
import Tooltip from "../../Shared/Components/Tooltip/tooltip";
import * as ReportUtils from "../../Shared/Utilities/reportUtils";
import { LoadingSpinner } from "@deluxe/unify-loading-spinner";
import { UserContext } from "../../Users/userContext";
import CustomReport from "../CustomReports/customReport";
import { parseInt } from "lodash";

function Report(props) {
  const apiOptions = { headers: { Authorization: `Bearer ${window.token}` } };
  const params = useParams();
  const reportType = params.reportType;
  const RANGE_MSG = "Range: Calendar shows only current year and adds a YTD column. Range: Split calendar shows actuals for current year and budget for remaining portion of the year.";
  const SUPPRESS_ZERO_ADD_FOOTER_MSG = "Zero lines will be suppressed even if they have non-zero drill down balances. You can choose to add a footer to the report to indicate that zeros have been suppressed.";
  let contextUser = null;
  const userContext = useContext(UserContext);
  if (userContext.user) {
    contextUser = userContext.user;
  }
  const INITIAL_REPORT_PARAMETERS = `&year=2023&month=9&rangeType=1&reportType=${ReportUtils.REPORT_TYPES[reportType]}&bankId=${window.bankId}&periodType=0&dataSourceType=0&dataSourceId=0&reverse=false&profitCenterSeq=-1&suppressZero=true&combined=false&summaryOnly=true&addFooter=true`;
  const [isLoading, setIsLoading] = useState(true);
  const [disableReverseOrder, setDisableReverseOrder] = useState(false);
  const [disableAddFooter, setDisableAddFooter] = useState(false);
  const [reportProperties, setReportProperties] = useState(null);
  const [visible, setVisible] = useState(false);
  const [reportParams, setReportParams] = useState(INITIAL_REPORT_PARAMETERS);

  const [reportControls, setReportControls] = useState({
    profitCenter: true,
    dataSource: true,
    doQTD: false,
    period: false,
    range: false,
    expandCenters: false,
    view: false,
    viewMonths: false,
    average: false,
    budget: false,
    forecast: false,
    yesterday: false,
    dateSelectedIndex: 0,
    viewSelectedIndex: 0,
    viewMonthsSelectedIndex: 1,
    suppressZerosVisibility: true,
    memos: false,
    projectIndex: 0,
  });

  const viewOptions = [
    { display: "Summary", value: "0" },
    { display: "Detailed", value: "1" },
  ];

  const RangeOptions = [
    { display: "Trailing", value: "0" },
    { display: "Calendar", value: "1" },
    { display: "Split Calendar", value: "2" },
  ];
  const [profitCenterOptions, setProfitCenterOptions] = useState(null);
  const averageList = [
    { display: "No", value: ReportUtils.AVG_OPTION_VAL.NONE },
    { display: "MTD", value: ReportUtils.AVG_OPTION_VAL.MTD },
    { display: "YTD", value: ReportUtils.AVG_OPTION_VAL.YTD },
  ];
  const [avgOptions, setAvgOptions] = useState(null);
  const [dataSourceOptions, setDataSourceOptions] = useState(null);
  const [dateOptions, setDateOptions] = useState(null);
  const [entityOptions, setEntityOptions] = useState(null);
  const [selectedEntity, setSelectedEntity] = useState(null);
  const [reverseSelection, setReverseSelection] = useState([]);
  const [suppressZeros, setSuppressZeros] = useState([
    { displayValue: "Suppress zeros", value: "0" },
  ]);
  const [addFooter, setAddFooter] = useState([
    { displayValue: "Add Footer", value: "0" },
  ]);
  const [avgSelection, setAvgSelection] = useState({
    display: "No",
    value: "0",
  });
  const [viewSelection, setViewSelection] = useState(null);
  const [rangeSelection, setRangeSelection] = useState(RangeOptions[0]);
  const [datasourceSelection, setDatasourceSelection] = useState(null);
  const [profitCenterSelection, setProfitCenterSelection] = useState({
    display: "Consolidated",
    value: "-1",
  });
  const [dateSelection, setDateSelection] = useState(null);

  const getEntityAccess = () => {
    let entityList = contextUser.entities.map((entity) => {
      return { display: entity.name, value: entity.bankId };
    });
    return entityList;
  };

  const getSelectedBank = () => {
    return selectedEntity !== null ? selectedEntity.value : window.bankId;
  }

  const populateProfitCenters = async (entity) => {
    const bankId = entity !== undefined ? entity.value : getSelectedBank();
    const allowConsolidated = ReportUtils.checkAllowConsolidated(contextUser, bankId);
    const firstItem = allowConsolidated ? { display: "Consolidated", value: "-1" } : { display: "Combined", value: "-1" };
    try{
      const pcs = await getPcPopulate(bankId);
      setProfitCenterOptions([firstItem, ...pcs]);
    }
    catch(e){
      console.log("Error getting profit center " + e.message);
    }
  }

  const fetchData = async (reportType) => {
    let entityList = getEntityAccess();
    setEntityOptions(entityList);
     setSelectedEntity(
      entityList.find((entity) => entity.value === window.bankId)
    );

    populateReportData(reportType);
    try {
      populateProfitCenters();
      const ds = await populateDataSource();
      setDataSourceOptions(ds);
      setDatasourceSelection(ds[0]);
      const transformedDate = await getActualDates();
      setDateOptions(transformedDate);
      setDateSelection(transformedDate[1]);
      setIsLoading(false);
    } catch (e) {
      console.log("Error getting report dropdown data " + e.message);
      setIsLoading(false);
    }
  };

  const getActualDates = async () => {
    let transformedDate = [];
    try {
      const actualDates = await axios.get(
        `${
          API_ROOT.glServiceEndpoint
        }/api/ReportParameter/GetDates?reportType=${
          ReportUtils.REPORT_TYPES[reportType]
        }&bankId=${getSelectedBank()}&dataSourceType=${ReportUtils.convertDataSourceToType("Actual")}`,
        apiOptions
      );
      transformedDate = actualDates.data.map((date) => {
        return { display: date, value: date };
      });
    } catch (e) {
      console.log("Error getting actual dates " + e.message);
    }
    return transformedDate;
  };

  const populateReportData = (reportType) => {
    switch (reportType) {
      case "BalanceSheetHistorical":
        setReportProperties({
          name: "Balance Sheet",
          type: ReportUtils.REPORT_TYPES.BalanceSheetHistorical,
        });
        const avg = [...averageList];
        avg.splice(2, 0, {
          display: "QTD",
          value: ReportUtils.AVG_OPTION_VAL.QTD,
        });
        setAvgOptions(avg);
        const data = {
          ...reportControls,
          view: true,
          range: true,
          average: true,
          yesterday: false,
          dateSelectedIndex: 1,
        };
        setReportControls(data);
        setViewSelection(viewOptions[data.viewSelectedIndex]);
        break;
      case "IncomeStatementHistorical":
        setReportProperties({
          name: "Income Statement",
          type: ReportUtils.REPORT_TYPES.IncomeStatementHistorical,
        });

        const incomeStatementData = {
          ...reportControls,
          view: true,
          range: true,
          yesterday: false,
          date1SelectedIndex: 1,
        };
        setReportControls(incomeStatementData);
        setViewSelection(viewOptions[incomeStatementData.viewSelectedIndex]);
        break;
      case "MarginAnalysisHistorical":
        setReportProperties({
          name: "Margin Analysis",
          type: ReportUtils.REPORT_TYPES.MarginAnalysisHistorical,
        });

        const marginAnalysisData = {
          ...reportControls,
          range: true,
          yesterday: false,
          date1SelectedIndex: 1,
          suppressZerosVisibility: false,
        };
        setReportControls(marginAnalysisData);
        disableSuppressZeros();
        break;
      default:
        break;
    }
  };
  const getPcPopulate = async (bankId) => {
    try {
      const profitCenters = await axios.get(
        `${API_ROOT.serviceEndpoint}/${window.fdic}/reportParams/profitCenter/${bankId !== undefined ? bankId : getSelectedBank()}/${contextUser.id}`
      );
      const transformedProfitCenters = profitCenters.data.map((pc) => {
        let displayText = pc.isInRegion
          ? "\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0" + pc.text
          : pc.text;
        return { display: displayText, value: pc.value };
      });
      return transformedProfitCenters;
    } catch (e) {
      console.log("Error populating profit centers ");
    }
  };

  const populateDataSource = async (entity) => {
    let ds = [];
    if (reportType === "ExecutiveSummaryCompare") {
      ds.push({ display: "No", value: "0" });
    }
    if(rangeSelection.value === "0" || rangeSelection.value === "1"){
      ds.push({ display: "Actual", value: "Actual" });
    }
    try {
      let dataSource = await axios.get(
        `${API_ROOT.glServiceEndpoint}/api/User?reportType=${ReportUtils.REPORT_TYPES[reportType]}&bankId=${entity !== undefined ? entity.value : getSelectedBank()}`,
        apiOptions
      );
      dataSource = dataSource.data;

      if (dataSource.isBudgetAvailable) {
        ds.push({ display: "Budget", value: "Budget" });
      }
      if (dataSource.isForecastAvailable) {
        ds.push({ display: "Forecast", value: "Forecast" });
      }
      if (
        dataSource.isYesterdayAvailable &&
        reportType !== "BalanceSheetHistorical"
      ) {
        ds.push({ display: "Yesterday", value: "Yesterday" });
      }
      setReportControls((prevReportControl) => {
        return {
          ...prevReportControl,
          budget: dataSource.isBudgetAvailable,
          forecast: dataSource.isForecastAvailable,
          yesterday:
            dataSource.isYesterdayAvailable &&
            reportType !== "BalanceSheetHistorical" &&
            reportType !== "IncomeStatementHistorical",
        };
      });
      if (
        ReportUtils.DATASOURCE_REPORTS.indexOf(reportType) !== -1 &&
        reportType !== "ExecutiveSummary" &&
        reportType !== "RatioAnalysis"
      ) {
        const projections = await getProjectionDataSources(entity);
        ds = [...ds, ...projections];
      }
      return ds;
    } catch (e) {
      console.log(e.message);
    }
  };

  const getProjectionDataSources = async (entity) => {
    let transformedDataSource = null;
    let _reportType = reportProperties ? reportProperties.type : ReportUtils.REPORT_TYPES[reportType];
    try {
      const projectionDataSources = await axios.get(
        `${API_ROOT.glServiceEndpoint}/api/ReportParameter/GetProjects?reportType=${_reportType}&bankId=${entity !== undefined ? entity.value : getSelectedBank()}`,
        apiOptions
      );
      transformedDataSource =
        projectionDataSources.data &&
        projectionDataSources.data.map((pds) => {
          return { display: pds.description.trim(), value: "P." + pds.id };
        });
    } catch (e) {
      console.log("Error getting projection data sources " + e.message);
    }
    return transformedDataSource;
  };

  const disableSuppressZeros = () => {
    setSuppressZeros([]);
    setAddFooter([]);
  };

  const showReport = async () => {
    let params = getReportParams();
    if(params !== reportParams){
      setIsLoading(true);
      setReportParams(params);
    }
    setVisible(false);
    //setIsLoading(false);
    return null;
  };

  const getUpdatedDataSource = async (range) => {
    let ds = [];
    let dateSelectedIndex = 0;
    if (range.value === "0" || range.value === "1") {
      ds = [
        { display: "Actual", value: "Actual" }
      ];

      setDisableReverseOrder(false);
      if (avgOptions) {
        let updatedAvg = enableQtdAvgOption(avgOptions, true);
        updatedAvg = enableAvgYtdOption(updatedAvg, true);
        setAvgOptions(updatedAvg);
      }
    } else {
      setDisableReverseOrder(true);
      if (reverseSelection.length > 0) {
        setReverseSelection([]);
      }
      if (avgOptions) {
        let updatedAvg = enableQtdAvgOption(avgOptions, false);
        updatedAvg = enableAvgYtdOption(updatedAvg, false);
        setAvgOptions(updatedAvg);
      }
    }
    let dataSource = await axios.get(
      `${API_ROOT.glServiceEndpoint}/api/User?reportType=${ReportUtils.REPORT_TYPES[reportType]}&bankId=${getSelectedBank()}`,
      apiOptions
    );
    dataSource = dataSource.data;

    if (dataSource.isBudgetAvailable) {
      ds.push({ display: "Budget", value: "Budget" });
    }
    if (dataSource.isForecastAvailable) {
      ds.push({ display: "Forecast", value: "Forecast" });
    }
    if (
      dataSource.isYesterdayAvailable &&
      reportType !== "BalanceSheetHistorical"
    ) {
      ds.push({ display: "Yesterday", value: "Yesterday" });
    }
    setReportControls({
        ...reportControls,
        budget: dataSource.isBudgetAvailable,
        forecast: dataSource.isForecastAvailable,
        yesterday:
          dataSource.isYesterdayAvailable &&
          reportType !== "BalanceSheetHistorical" &&
          reportType !== "IncomeStatementHistorical"
    });

    if (range.value === "0") {
      dateSelectedIndex = 1;
    }
    setDateSelection(dateOptions[dateSelectedIndex]);
    return ds; 
  }


  const getReportParams = () => {
    let tempDate = dateSelection !== null ? dateSelection.display : null;
    tempDate = tempDate.replace(" ", " 1, ");
    const date = new Date(tempDate);
    const month = date.getMonth() + 1;
    const year = date.getFullYear();
    const rangeType = parseInt(rangeSelection.value) + 1;
    const reportType = reportProperties.type;
    const periodType = avgSelection.value ? parseInt(avgSelection.value) : 0;
    const dataSourceType = ReportUtils.convertDataSourceToType(
      datasourceSelection.value
    );
    const dataSourceId =
      dataSourceType === 3
        ? parseInt(
            datasourceSelection.value.substr(
              2,
              datasourceSelection.display.length - 1
            )
          )
        : 0;
    const reverse =
      reverseSelection && reverseSelection.length > 0 ? true : false;
    const profitCenterSeq = profitCenterSelection.value;
    const suppressZero = suppressZeros.length > 0 ? true : false;
    const combined = profitCenterSelection.display === "Combined";
    const summaryOnly = parseInt(viewSelection.value) === 0 ? true : false;
    return `&year=${year}&month=${month}&rangeType=${rangeType}&reportType=${reportType}&bankId=${getSelectedBank()}&periodType=${periodType}&dataSourceType=${dataSourceType}&dataSourceId=${dataSourceId}&reverse=${reverse}&profitCenterSeq=${profitCenterSeq}&suppressZero=${suppressZero}&combined=${combined}&summaryOnly=${summaryOnly}&addFooter=${addFooter.length > 0}`;
  };

  const changeRange = async (range) => {
    setRangeSelection(range);
    let ds = await getUpdatedDataSource(range);
    const projectionData = await getProjectionDataSources();
    if (projectionData) {
      ds = [...ds, ...projectionData];
    }
    setDataSourceOptions(ds);
    setDatasourceSelection(ds[0]);
  };

  const enableQtdAvgOption = (avgOptions, enabled) => {
    let updatedAvg = [...avgOptions];
    let qtd = updatedAvg.find((avg) => avg.display === "QTD");
    if (qtd) {
      if (!enabled) {
        updatedAvg = updatedAvg.filter((avg) => avg.display !== "QTD");
        let currentValue = avgSelection.value;
        if (currentValue === qtd.value) {
          setAvgSelection(updatedAvg[0]);
        }
      }
    } else {
      if (enabled) {
        updatedAvg.splice(2, 0, {
          display: "QTD",
          value: ReportUtils.AVG_OPTION_VAL.QTD,
        });
      }
    }
    return updatedAvg;
  };

  const enableAvgYtdOption = (avgOptions, enabled) => {
    let updatedAvg = [...avgOptions];
    let ytd = updatedAvg.find((avg) => avg.display === "YTD");
    if (ytd) {
      if (!enabled) {
        updatedAvg = updatedAvg.filter((avg) => avg.display !== "YTD");
        if (avgSelection.value === ytd.value) {
          setAvgSelection(updatedAvg[0]);
        }
      }
    } else {
      if (enabled) {
        updatedAvg.splice(3, 0, {
          display: "YTD",
          value: ReportUtils.AVG_OPTION_VAL.YTD,
        });
      }
    }
    return updatedAvg;
  };

  const changeDate = (dataSource, entity) => {
    setDatasourceSelection(dataSource);
    let ds = dataSource.value;
    let avg = 0;
    if (avgSelection) {
      avg = avgSelection;
    }
    avg = avg.display === "QTD" ? ReportUtils.AVG_OPTION_VAL.QTD : avg;
    loadDateDropdown(avg, ds, entity);
  };

  const changeAverage = (avg) => {
    setAvgSelection(avg);
    loadDateDropdown(0, datasourceSelection.value);
  };

  const loadDateDropdown = async (avg, ds, entity) => {
    let result = null;
    let bankId = entity !== undefined ? entity.value : getSelectedBank();
    try {
      if (ds.startsWith("P.")) {
        ds = ds.substring(2);
        result = await axios.get(
          `${
            API_ROOT.glServiceEndpoint
          }/api/ReportParameter/GetDates?reportType=${
            reportProperties.type
          }&bankId=${bankId}&dataSourceType=${ReportUtils.convertDataSourceToType(
            ds
          )}&projectID=${ds}`,
          apiOptions
        );
      } else {
        result = await axios.get(
          `${
            API_ROOT.glServiceEndpoint
          }/api/ReportParameter/GetDates?reportType=${
            reportProperties.type
          }&bankId=${bankId}&dataSourceType=${ReportUtils.convertDataSourceToType(ds)}`,
          apiOptions
        );
      }
      result = result.data;
    } catch (e) {
      console.log("Error populating dates " + e.message);
    }
    if (result) {
      const dates = [];
      const dateVar = new Date();
      const targetMonth = dateVar.getMonth() == 0 ? 11 : dateVar.getMonth() - 1;
      const targetYear =
        dateVar.getMonth() == 0
          ? dateVar.getFullYear() - 1
          : dateVar.getFullYear();
      const dateFormat =
        ReportUtils.MONTH_NAMES[targetMonth] + " " + targetYear;
      let resultMonth = 0;
      let resultYear = 0;
      const selectedDate = dateSelection.value;
      let newSelection = "";

      for (var i = 0; i < result.length; i++) {
        resultMonth = result[i].indexOf("January") === 0 ? 1 : resultMonth;
        resultMonth = result[i].indexOf("February") === 0 ? 2 : resultMonth;
        resultMonth = result[i].indexOf("March") === 0 ? 3 : resultMonth;
        resultMonth = result[i].indexOf("April") === 0 ? 4 : resultMonth;
        resultMonth = result[i].indexOf("May") === 0 ? 5 : resultMonth;
        resultMonth = result[i].indexOf("June") === 0 ? 6 : resultMonth;
        resultMonth = result[i].indexOf("July") === 0 ? 7 : resultMonth;
        resultMonth = result[i].indexOf("August") === 0 ? 8 : resultMonth;
        resultMonth = result[i].indexOf("September") === 0 ? 9 : resultMonth;
        resultMonth = result[i].indexOf("October") === 0 ? 10 : resultMonth;
        resultMonth = result[i].indexOf("November") === 0 ? 11 : resultMonth;
        resultMonth = result[i].indexOf("December") === 0 ? 12 : resultMonth;
        resultYear = result[i].substr(result[i].length - 4);
        var addMonth = false;
        if (
          (avg === "2" &&
            (result[i].indexOf("March") == 0 ||
              result[i].indexOf("June") == 0 ||
              result[i].indexOf("September") == 0 ||
              result[i].indexOf("December") == 0)) ||
          avg !== "2"
        )
          addMonth = true;
        else if (
          avg === "2" &&
          resultMonth == dateVar.getMonth() + 1 &&
          resultYear == dateVar.getFullYear()
        )
          addMonth = true;
        else if (avg === "2" && reportType === "MarginAnalysis")
          addMonth = true;

        if (addMonth) {
          newSelection = result[i] === selectedDate ? result[i] : newSelection;
          dates.push({ display: result[i], value: result[i] });
        }
      }
      if (newSelection === "") {
        newSelection = dateFormat;
      }

      if (result.indexOf(newSelection) === -1) {
        newSelection = result[0];
      }
      setDateOptions([...dates]);
      setDateSelection({ display: newSelection, value: newSelection });
    } else {
      setDateOptions(null);
      setDateSelection(null);
    }
  };

  const suppressZerosClicked = (e) => {
    if (suppressZeros.length > 0) {
      setAddFooter([]);
      setDisableAddFooter(true);
    } else {
      setDisableAddFooter(false);
    }
    setSuppressZeros(e);
  };

  const toggleSideDrawer = () => {
    setVisible(!visible);
  };
  const entityChanged = async (entity) => {
    let dateSelectedIndex = 0;
    setSelectedEntity(entity);
    //gets profit centers
    populateProfitCenters(entity);
    //update datasource option and projection data sources
    const ds = await populateDataSource(entity);
    setDataSourceOptions(ds);
    //change date
    changeDate(ds[0], entity);
  }

  useEffect(() => {
    if (reportType !== undefined){
      fetchData(reportType)
    };
    return () => {
    };
  }, []);



  return (
    <>
      <LoadingSpinner isActive={(isLoading || !(profitCenterOptions || dataSourceOptions || dateOptions ||  dateSelection))} />
      {reportParams && <CustomReport  headerTitle={reportProperties && reportProperties.name}  reportParameters = {reportParams} suppressZeros={suppressZeros.length > 0} addFooter={addFooter.length > 0} setLoadingState={setIsLoading} />}
       {reportProperties && <SideDrawer
          show={visible}
          toggleSideDrawer={toggleSideDrawer}
          sideDrawerClosed={() => setVisible(false)}
          refreshClicked={showReport}
          headerTitle={reportProperties.name}
        >
          <div>
            <UnifyDropdown
              label="Entities"
              labelClasses={styles.report_label}
              buttonClasses={styles.report_button}
              listboxClasses={styles.report_list}
              wrapperClasses={styles.report_dropdown_style}
              isInlineLabel={true}
              selection={selectedEntity}
              onSelect={entityChanged}
              controlId="dropdown-entities"
              options={entityOptions}
            />
            {reportControls.view && (
              <div data-testid="report-view">
                <UnifyDropdown
                  label="View"
                  labelClasses={styles.report_label}
                  buttonClasses={styles.report_button}
                  listboxClasses={styles.report_list}
                  wrapperClasses={styles.report_dropdown_style}
                  isInlineLabel={true}
                  selection={viewSelection}
                  onSelect={setViewSelection}
                  controlId="dropdown-view"
                  options={viewOptions}
                />
              </div>
            )}

            {reportControls.range && (
              <div data-testid="report-range">
                <Tooltip title={RANGE_MSG}>
                  <UnifyDropdown
                      label="Range"
                      labelClasses={styles.report_label}
                      buttonClasses={styles.report_button}
                      listboxClasses={styles.report_list}
                      wrapperClasses={styles.report_dropdown_style}
                      isInlineLabel={true}
                      selection={rangeSelection}
                      onSelect={changeRange}
                      controlId="dropdown-range"
                      options={RangeOptions}
                    />
                </Tooltip>
              </div>
            )}
            {reportControls.profitCenter && window.bankId >= 0 && (
              <div data-testid="report-profitCenter">
                <UnifyDropdown
                  label="Profit Center"
                  labelClasses={styles.report_label}
                  buttonClasses={styles.report_button}
                  listboxClasses={styles.report_list}
                  wrapperClasses={styles.report_dropdown_style}
                  isInlineLabel={true}
                  selection={profitCenterSelection}
                  onSelect={setProfitCenterSelection}
                  controlId="dropdown-profitcenter"
                  options={profitCenterOptions}
                />
              </div>
            )}

            {reportControls.dataSource && dataSourceOptions && (
              <div data-testid="report-dataSource">
                <UnifyDropdown
                  label="Data Source"
                  labelClasses={styles.report_label}
                  buttonClasses={styles.report_button}
                  listboxClasses={styles.report_list}
                  wrapperClasses={styles.report_dropdown_style}
                  isInlineLabel={true}
                  selection={datasourceSelection}
                  onSelect={changeDate}
                  controlId="dropdown-datasource"
                  options={dataSourceOptions}
                />
              </div>
            )}
            {reportControls.average && (
              <div data-testid="report-average">
                <UnifyDropdown
                  label="Average"
                  labelClasses={styles.report_label}
                  buttonClasses={styles.report_button}
                  listboxClasses={styles.report_list}
                  wrapperClasses={styles.report_dropdown_style}
                  isInlineLabel={true}
                  selection={avgSelection}
                  onSelect={changeAverage}
                  controlId="dropdown-average"
                  options={avgOptions}
                />
              </div>
            )}

            {dateOptions && (
              <div data-testid="report-date">
                <UnifyDropdown
                  label="Dates"
                  labelClasses={styles.report_label}
                  buttonClasses={styles.report_button}
                  listboxClasses={styles.report_list}
                  wrapperClasses={styles.report_dropdown_style}
                  isInlineLabel={true}
                  selection={dateSelection}
                  onSelect={setDateSelection}
                  controlId="dropdown-date"
                  options={dateOptions}
                />
              </div>
            )}
            <div className={styles.reverse_order_ckbox}>
              <UnifyCheckbox
                controlId="reverse-order"
                label=""
                isHiddenLabel={true}
                isDisabled={disableReverseOrder}
                name="reverse-order"
                selected={reverseSelection}
                setSelected={setReverseSelection}
                options={[
                  { displayValue: "Reverse chronological order", value: "0" },
                ]}
              />
            </div>
            {reportControls.suppressZerosVisibility && (
              <Tooltip title={SUPPRESS_ZERO_ADD_FOOTER_MSG}>
                 <UnifyCheckbox
                      controlId="suppress-zero"
                      label=""
                      isHiddenLabel={true}
                      isDisabled={false}
                      name="suppress-zero"
                      selected={suppressZeros}
                      setSelected={suppressZerosClicked}
                      options={[{ displayValue: "Suppress zeros", value: "0" }]}
                    />
                    <div className={styles.footer}>
                      <UnifyCheckbox
                        controlId="add-footer"
                        label=""
                        isHiddenLabel={true}
                        isDisabled={disableAddFooter}
                        name="add-footer"
                        selected={addFooter}
                        setSelected={setAddFooter}
                        options={[{ displayValue: "Add footer", value: "0" }]}
                      />
                    </div>
                    <div>
                    </div>
              </Tooltip>
            )}
          </div>
        </SideDrawer>}
    </>
  );
}

export default Report;