import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import '@fortawesome/fontawesome-free/css/all.css'
import 'bootstrap/dist/css/bootstrap.min.css';
import 'unify-core/dist/css/unify.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import UserContextProvider from './Users/userContext';
global.$ = global.jQuery = require('jquery');
var dt = require( 'datatables.net' );

//have to add jquery to add datatables as this is what unify currently uses, following same api(?)
window.unify = {
  ...(window.unify || {}),
  $: global.$
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<UserContextProvider><App /></UserContextProvider>);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
