export const REPORT_TYPES = {
    ExecutiveSummary: 1,
    ExecutiveSummaryHistorical: 2,
    RatioAnalysis: 3,
    BalanceSheetHistorical: 4,
    EarningAssetsMix: 5,
    FundingMix: 6,
    NonEarningAssetsMix: 7,
    LoanConcentration: 8,
    IncomeStatement: 9,
    IncomeStatementHistorical: 10,
    NonInterestIncome: 11,
    NonInterestExpense: 12,
    MarginAnalysis: 13,
    MarginAnalysisHistorical: 14,
    MarginAnalysisGrid: 15,
    MarginAnalysisComponents: 16,
    CapitalRequirements: 17,
    BalanceSheet: 18,
    LiquidityReport: 19,
    LoanLossAdequacy: 20,
    TexasRatio: 21,
    HistoricalCapitalRatios: 23,
    MonthlyDashboard: 24
}

export const PERIOD_TYPES = {
    None: 0,
    Mtd: 1,
    Qtd: 2,
    Ytd: 3
}

export const AVG_OPTION_VAL = { NONE: '0', MTD: '1', QTD: '2', YTD: '3' };

export const DATASOURCE_REPORTS = [
    "ExecutiveSummary", "ExecutiveSummaryCompare", "ExecutiveSummaryHistorical", "RatioAnalysis", "BalanceSheetHistorical",
    "LoanConcentration", "IncomeStatementHistorical", "MarginAnalysis", "MarginAnalysisHistorical", "MarginGraph", "TexasRatio"
];

export const MONTH_NAMES = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August',
'September', 'October', 'November', 'December'];

export const convertDataSourceToType = (ds) => {
    switch (ds) {
        case "Actual":
            return 0;
        case "Budget":
            return 1;
        case "Forecast":
            return 2;
        case "Yestersay":
            return 4;
        default:
            return 3; // implies new Projects
    }
}


export const checkAllowConsolidated = (user, bankId) => {
    let allowConsolidated = false;
    if (user && user.entities) {
        const entity = user.entities.find(e => e.bankId === bankId);
        if (entity.profitCenters) {
            let allowedProfitCenters = entity.profitCenters;

            if (allowedProfitCenters.length == 1 && allowedProfitCenters[0].id == "-1") {
                allowConsolidated = true;
            }
            return allowConsolidated;
        }
    }
}


export const REPORT_MESSAGE = (report_name) => {
    let message =  null;
    switch(report_name){
        case 'Balance Sheet':
            message = <p>Select criteria for {report_name} Report.</p>;
            break;
        case 'Income Statement':
            message = (<p>Select criteria for {report_name} Report to see monthly view for 12 months.<br /><br/><span>Note : </span> Range: Calendar shows only current year and adds a YTD column. Range: Split calendar shows actuals for current year and budget for remaining portion of the year.</p>)
            break;
        case 'Margin Analysis':
            message = (<p>Select criteria for the {report_name} Report to see monthly view for 12 months.<br /><br/><span>Note : </span> Range: Calendar shows only current year. Range: Split calendar shows actuals for current year and budget for remaining portion of the year.</p>);
            break;
        default:
            message = null;
    }
    return message;
}



