import _ from "lodash";
const SPECIFIC_GROUP = "SpecificGroup";
const OUT_OF_BALANCE = "OutOfBalance";
const ACCOUNTGROUP_SUBTOTAL = "AccountGroupSubtotal";
const ACCOUNTSUBGROUP_SUBTOTAL = "AccountSubGroupSubtotal";
const ACCOUNTGROUP_MAJOR_SUBTOTAL = "AccountGroupMajorSubtotal";
const SECTION_GRANDTOTAL = "SectionGrandTotal";
const REPORT_GRANDTOTAL = "ReportGrandTotal";
const Account_Group = "AccountGroup";
const MONTH_NAME_LIMIT = 3;


 const LINE_ITEM_TYPES = {
    Category: 1,
    TopLevelGroup: 2,
    MidLevelGroup: 3,
    SpecificGroup: 4,
    Account: 5,
    ProfitCenter: 6,
    Header: 7,
    GroupItem: 8,
    Subtotal: 9,
    SectionSubtotal: 10,
    SectionTotal: 11,
    OutOfBalance: 12,
  };

  const LINE_ITEM_CATEGORIES = {
    SectionGroup: 1,
    AccountGroup: 2,
    AccountGroupSubtotal: 3,
    AccountGroupMajorSubtotal: 4,
    AccountSubGroup: 5,
    AccountSubGroupSubtotal: 6,
    Account: 7,
    ProfitCenter: 8,
    SectionGrandTotal: 9,
    ReportGrandTotal: 10,
    OutOfBalance: 11,
  };
  const getDataSourceName = (dataSourceDetails, index) => {
    return index <= dataSourceDetails.primaryDataSourceTo ? dataSourceDetails.primaryDataSource : dataSourceDetails.secondaryDataSource
  }


  const buildHeader = (str, length, periodYear, dataSource) => {
  const month = str.length > length ? str.slice(0, length) : str;
  const header = `${month} ${periodYear}`;
  return (<div ><span style={{fontSize:"12px"}}>{dataSource}</span><br /><span style={{fontSize:"12px"}}>{header}</span></div>)
  };

   const numFormatter = (field, node) => {
     if (node.data[field] !== 0 && !node.data[field]) {
      return <span></span>;
    }
    const formattedValue = Math.abs(node.data[field]).toLocaleString("en-US", { maximumFractionDigits: 0 });

      let style = { fontSize: "10px" };

      if (node.data[field] < 0) {
        if (node.lineItemCategory === LINE_ITEM_CATEGORIES[REPORT_GRANDTOTAL]) {
          style.textDecoration = "overline";
          style.borderBottom = "3px double black";
          return <span style={style}>({formattedValue})</span>;
        } else if ( node.lineItemCategory === LINE_ITEM_CATEGORIES[SECTION_GRANDTOTAL]) {
          style.textDecoration = "underline overline";
          return <span style={style}>({formattedValue})</span>;
        }
        else if ((node.lineItemCategory ===LINE_ITEM_CATEGORIES[Account_Group] ||node.lineItemCategory === LINE_ITEM_CATEGORIES[ACCOUNTSUBGROUP_SUBTOTAL] )  && node.expanded === true) {
          style.textDecoration = "overline";
          return <span style={style}>({formattedValue})</span>;
        }
        else if (node.lineItemCategory ===LINE_ITEM_CATEGORIES[ACCOUNTGROUP_SUBTOTAL]|| node.lineItemCategory ===LINE_ITEM_CATEGORIES[ACCOUNTGROUP_MAJOR_SUBTOTAL])
      {
        style.textDecoration = "overline";
        return <span style={style}>({formattedValue})</span>;
      }
        else {
        style.textDecoration = "none";
        return <span style={style}>({formattedValue})</span>;
      }
      } 
      else{
      if (node.lineItemCategory === LINE_ITEM_CATEGORIES[REPORT_GRANDTOTAL]) {
        style.textDecoration = "overline";
        style.borderBottom = "3px double black";
        return <span style={style}>{formattedValue}</span>;
      } else if ( node.lineItemCategory === LINE_ITEM_CATEGORIES[SECTION_GRANDTOTAL]) {
        style.textDecoration = "underline overline";
        return <span style={style}>{formattedValue}</span>;
      }
      else if ( (node.lineItemCategory === LINE_ITEM_CATEGORIES[Account_Group] ||node.lineItemCategory === LINE_ITEM_CATEGORIES[ACCOUNTSUBGROUP_SUBTOTAL] ) && node.expanded === true) {
        style.textDecoration = "overline";
        return <span style={style}>{formattedValue}</span>;
      }
      else if (node.lineItemCategory ===LINE_ITEM_CATEGORIES[ACCOUNTGROUP_SUBTOTAL] || node.lineItemCategory ===LINE_ITEM_CATEGORIES[ACCOUNTGROUP_MAJOR_SUBTOTAL])
      {
        style.textDecoration = "overline";
        return <span style={style}>{formattedValue}</span>;
      }


    }
    
    return <span style={{ fontSize: "10px" }}>{formattedValue}</span>;
    

  };

   const isTotal = (group) => {
    return (
      group.lineItemCategory === LINE_ITEM_CATEGORIES[ACCOUNTGROUP_MAJOR_SUBTOTAL] ||
      group.lineItemCategory === LINE_ITEM_CATEGORIES[ACCOUNTGROUP_SUBTOTAL] ||
      group.lineItemCategory === LINE_ITEM_CATEGORIES[ACCOUNTSUBGROUP_SUBTOTAL] ||
      group.lineItemCategory === LINE_ITEM_CATEGORIES[SECTION_GRANDTOTAL]
    );
  };

  const isSpecificGroup = (group) => {
    return group.lineItemType === LINE_ITEM_TYPES[SPECIFIC_GROUP];
  };

  //This method is basically about populating alternatePeriodResult. I am not sure yet where I need alternate balance
 export const populateAlternatePeriodValues = (lineItems, periodHeaders, isGroupView) => {
    let items = lineItems;

    if (isGroupView) {
        items.forEach((item) => {
        if (item.lineItemData.periodValues) {
          //NOT SURE IF THIS IS NEEDED YET
          item.lineItemData.isClickable =
            (!item.lineItemData.alternatePeriodValues &&
              item.children &&
              item.children.length === 0 &&
              !isTotal(item) &&
              !isSpecificGroup(item)) ||
            item.lineItemData.alternatePeriodValues;

            let periods = [];
            periodHeaders.forEach(periodHeader => {
                let period = null;
                let periodResult, alternatePeriodResult;
                periodResult = item.lineItemData.periodValues.find(x => 
                    x.periodYear === periodHeader.periodYear &&
                    x.periodNumber === periodHeader.periodNumber &&
                    x.dataSourceType === periodHeader.dataSourceType &&
                    x.dataSourceId === periodHeader.dataSourceId &&
                    x.periodType === periodHeader.periodType)
                if (item.lineItemData.alternatePeriodValues) {
                    alternatePeriodResult = item.lineItemData.alternatePeriodValues.find(x =>
                        x.periodYear === periodHeader.periodYear &&
                        x.periodNumber === periodHeader.periodNumber &&
                        x.dataSourceType === periodHeader.dataSourceType &&
                        x.dataSourceId === periodHeader.dataSourceId &&
                        x.periodType === periodHeader.periodType)
                }
                if(periodResult){
                    period = {
                        periodYear: periodResult.periodYear,
                        periodNumber: periodResult.periodNumber,
                        periodName: periodResult.periodName,
                        balance: periodResult.balance,
                        dataSourceType: periodResult.dataSourceType,
                        dataSourceId: periodResult.dataSourceId,
                        profitCenterId: periodResult.profitCenterId,
                        periodType: periodResult.periodType
                    }
                    if(alternatePeriodResult){
                        period.alternateBalance = alternatePeriodResult.balance
                    }
                }
                if (period) periods.push(period);
                item.lineItemData.periodValues = periods

            })
            if(item.children && item.children.length > 0){
                item.children = populateAlternatePeriodValues(item.children, periodHeaders, isGroupView);
            }
        }
      });
    }
    return items;
  };


// Not needed yet. Kept for reference. May be is needed for future report
  export const isTopLevelTotal = (group) => {
    return (group.lineItemCategory === LINE_ITEM_CATEGORIES[ACCOUNTGROUP_SUBTOTAL]) || isTopLevelMajorTotal(group)
  }

  // Not needed yet. Kept for reference. May be is needed for future report
  export const isTopLevelMajorTotal = (group) => {
    return group.lineItemCategory === LINE_ITEM_CATEGORIES[ACCOUNTGROUP_MAJOR_SUBTOTAL]
    || group.lineItemCategory ===LINE_ITEM_CATEGORIES[SECTION_GRANDTOTAL]
  }
 // Not needed yet. Kept for reference. May be is needed for future report
  export const isReportGrandTotal = (group) => {
    return group.lineItemCategory === LINE_ITEM_CATEGORIES[REPORT_GRANDTOTAL];
  }

  export const buildColumns = (columnData, dataSource) => {
    let dataSourceDetails = dataSource[0].dataSourceName;
    let cols = [
      {
        field: "name",
        header: <div><span></span><br /><span></span></div>,
        expander: true,
        frozen: true,
        align: "left",
        width: "350px",
      },
    ];

    if(dataSource.length > 1){
      dataSourceDetails = {
        primaryDataSource: dataSource[0].dataSourceName,
        primaryDataSourceFrom: dataSource[0].fromMonth - 1,
        primaryDataSourceTo: dataSource[0].toMonth - 1,
        secondaryDataSource: dataSource[1].dataSourceName
     }
    }
    let dataSourceName = dataSourceDetails;

    for (let i = 0; i < columnData.length; i++) {
      if (dataSource.length > 1) {
        dataSourceName = getDataSourceName(dataSourceDetails, i);
      }
      cols.push({
        field: `col${i}`,
        header: buildHeader(columnData[i].periodName, MONTH_NAME_LIMIT, columnData[i].periodYear, dataSourceName),
        align: "right",
         body: (n) => {
          return numFormatter(`col${i}`, n);
        },
      });
    }
   return cols;
  };

  export const buildNodeData = (item, columnData, isGroupView) => {
    let nodeData = null;
    if(isGroupView || (!isGroupView && item.name === "Totals")){
        nodeData = {name: item.name}
    }
    else{
       nodeData = {name: `${item.id} - ${item.name}`}
    }
    if(columnData){
        let balanceCols = columnData.slice(1);
        let periodBalance = [];
        item.lineItemData.periodValues.forEach((periodValue) => {
            periodBalance.push(periodValue.balance);
        })
        for(let i = 0; i < balanceCols.length; i++){
            nodeData[balanceCols[i].field] = periodBalance[i];
        }
    }
    return nodeData;
  }

  export const loadNode = (item, columnData, isTopLevel, isGroupView, isDetailedReport) => {
    let className = "";
    let totNode = null;
    let node = {
        id: item.id,
        key: item.id.toString(),
        lineItemNumber: item.lineItemNumber,
        lineItemCategory: item.lineItemCategory,
        lineItemType: item.lineItemType,
        data: buildNodeData(item, columnData, isGroupView),
        isClickable: isTopLevel ? (item.children && item.children.length === 0 && !isTotal(item) && !isReportGrandTotal(item) && !isSpecificGroup(item)) : (item.children && item.children.length === 0)
        /*Here isTopLevel refers to the initial report view without expanding nodes. It was used because isClickable rule varies in top level view and all 
       other views (view when node is expanded, account level and profit center level)*/
    };

    if(isGroupView){
        node.expanded = item.isExpanded;
        if((node.expanded || isDetailedReport) && item.children && item.children.length > 0){
          if(!node.expanded) node.expanded = true;
          totNode = _.cloneDeep(node);
          node.data = { name: node.data.name };
          totNode.id = "tot" + totNode.id;
          totNode.key = "tot" + totNode.key;
          totNode.isClickable = false;
          totNode.className = "bd-group-total-row";
          delete totNode.children;
        }
    }else{
        node.leaf = !item.isExpanded;
        node.bankId = item.lineItemData.bankId;
    }
   
    if(isTotal(item)){
      className = 'report-total';
    }
    if (isReportGrandTotal(item)) {
      className = 'report-grandtotal';
  } 
    if(node.isClickable){
      className = "report-group-drilldown";
    }
    if(className.length > 0){
      node.className = className;
    }
    if(item.children && item.children.length > 0){
        node.children = [];
        item.children.forEach((childData) => {
          if(!childData.suppressZero){
            node.children.push(loadNode(childData, columnData, false, isGroupView, isDetailedReport));
          }
        });
        if(totNode){
          node.children.push(totNode);
        } 
    }
    return node;
  }

 export const loadNodes = (lineItems, columnData, isGroupView, isDetailedReport) => {
    let nodesData = [];
    lineItems.forEach((lineItem) => {
      if(!lineItem.suppressZero){
        let node = {
          id: lineItem.id,
          key: lineItem.id,
          data: {name: lineItem.name},
          className: "report-section"
          };
          nodesData.push(node);
          if(lineItem.children && lineItem.children.length > 0){
            lineItem.children.forEach((item) => {
              if(!item.suppressZero){
                nodesData.push(loadNode(item, columnData, true, isGroupView, isDetailedReport));
              }
            })      
            if(!isGroupView){
            let totNode = _.cloneDeep(lineItem);
            totNode.name = 'Totals';
            totNode.id = "tot" + totNode.id;
            totNode.key = "tot" + totNode.id;
            totNode.isExpanded = false;
            totNode.children = null;
            totNode.isTotal = true;
            totNode.lineItemCategory = 9
            nodesData.push(loadNode(totNode, columnData, false, isGroupView));
            }
          }
          //For out of balance line item.
          if(isGroupView && lineItem.lineItemCategory === LINE_ITEM_CATEGORIES[OUT_OF_BALANCE]){
            let childOutOfBalanceNode = _.cloneDeep(lineItem);
             childOutOfBalanceNode.id = "child"+lineItem.id;
             childOutOfBalanceNode.key = "child"+lineItem.id;
             nodesData.push(loadNode(childOutOfBalanceNode, columnData, true, isGroupView));
         }
      }
    });
      return nodesData;
  }

  export const footer = <div>Rows with zero balances are suppressed.</div>;




