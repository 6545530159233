const BaseTheme = {
  name: 'Base',
  custom: {
    navLinkUnderline: '#d61120',
    navLinkColor: '#285bc5',
    backgroundColor: '#f7f7f7'
  },
  colors: {
    // YOU CAN CHANGE THESE COLORS
    primary: '#296ED6',
    secondary: '#000f0c',
    brandAccent: '#d61120',
    accent1: '#b8dade',
    accent2: '#faef69',
    globalHeaderIcons: '#ffffff',
    globalHeaderLinkText: '#ffffff',
    buttonText: '#ffffff',
    buttonHoverBG: '#155AC2',
    buttonFocusBG: '#1055BD',
    buttonFocusShadow: '#87aade',
    // YOU CAN'T CHANGE THESE COLORS
    neutralWhite: '#ffffff',
    neutralBackground: '#f7f7f7',
    neutralLight: '#e0e0e0',
    neutralBgGrid: '#efefef',
    neutralMediumLight: '#f4f4f4',
    neutralMediumDarker: '#cccccc',
    neutralMedium: '#929292',
    neutralMediumDarkest: '#666666',
    neutralDark: '#333333',
    neutralBlack: '#000000',
    alertError: '#f62942',
    alertWarning: '#dfc700',
    alertConfirm: '#058256',
    alertInfo: '#2769cb',
    alertErrorShade: '#fff3f4',
    alertWarningShade: '#fff8ea',
    alertConfirmShade: '#f1f8ea',
    alertInfoShade: '#edf5ff',
    border: '#cccccc'
  },
  font: {
    main: {
      regular: 'metro-sans-regular, Arial',
      light: 'metro-sans-light',
      bold: 'metro-sans-bold'
    },
    secondary: {
      regular: 'andale-mono-std',
      boldd: 'andale-mono-std-bold'
    },
    weight: {
      light: 300,
      normal: 400,
      bold: 500,
      bolder: 600
    },
    size: {
      base: '1rem',
      utilNav: '0.75rem'
    }
  },
  hTags: {
    h1: {
      fontSize: '3rem',
      lineHeight: '4.5rem'
    },
    h2: {
      fontSize: '2.25rem',
      lineHeight: '3.375rem'
    },
    h3: {
      fontSize: '2rem',
      lineHeight: '3rem'
    },
    h4: {
      fontSize: '1.5rem',
      lineHeight: '2.25rem'
    },
    h5: {
      fontSize: '1.25rem',
      lineHeight: '1.875rem'
    },
    h6: {
      fontSize: '1.125rem',
      lineHeight: '1.6875rem'
    }
  },
  gridBreakpoints: {
    xs: '0',
    sm: '576px',
    md: '768px',
    lg: '992px',
    xl: '1200px'
  },
  border: {
    radius: '0.188rem'
  }
};

module.exports = BaseTheme;