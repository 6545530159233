import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Container,
  Row,
  Col,
  Form,
  FormGroup,
  Label,
  Input
} from 'unify-react';
import { LoadingSpinner } from '@deluxe/unify-loading-spinner';
import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import axios from 'axios';
import data from "../mock_data/peerDataTable";
import PeerGroupSearchTable from './peerGroupSearchTable';

let filterFunction = ( settings, data, dataIndex ) => {
    let assets = parseFloat( data[5].replace(/[\$\,]/g, '') ) || 0;
    let min = parseFloat( window.unify.$('#min-assets').val().replace(/[\$\,]/g, ''));
    let max = parseFloat( window.unify.$('#max-assets').val().replace(/[\$\,]/g, ''));

    if ( ( isNaN( min ) && isNaN( max ) ) ||
         ( isNaN( min ) && assets <= max ) ||
         ( min <= assets   && isNaN( max ) ) ||
         ( min <= assets   && assets <= max ) )
    {
        return true;
    }
    return false;
};

const loadMinMax = function(){
  window.unify.$.fn.dataTable.ext.search.push(filterFunction);
};

function PeerGroupSearchModal(props) {
  let confirmedPeerGroups = props.confirmedPeerGroups;
  const [updateTable, setUpdateTable] = useState(0);
  const [data, setData] = useState({
    isLoading: false
  })

  const [dataTable, setDataTable] = useState(null);

  const updateInput = event => {
    dataTable
      .columns(event.target.name + ':name')
      .search(event.target.value)
      .draw();
    setUpdateTable(updateTable + 1);
  }

  const updateMinMax = event => {
    dataTable.draw();
  };

  const saveSelections = async (event) => {
    event.preventDefault();
    props.saveSelections();
    props.handleClose();
  }

  const destroy = () => {
    window.unify.$.fn.dataTable.ext.search.splice(window.unify.$.fn.dataTable.ext.search.indexOf(filterFunction, 1));
  };

  useEffect(() => {
      loadMinMax()
      return () => destroy();
  }, []);

  return (<Modal className='peergroup-search-modal' isOpen={props.show} toggle={props.handleClose} tabIndex="-1" role="dialog" size="md">
        <div className="modal-content modal-accent-brand">
            <ModalHeader toggle={props.handleClose}>
              <span className="modal-override-header">
                Search for Peers
              </span>
            </ModalHeader>
            <ModalBody>
            {data.isLoading ? (<LoadingSpinner isActive={data.isLoading} />) : (
            <>
              <Container>
                <div class="modal-description">Select criteria to find peers to add to a group.</div>
                <Row>
                  <Col sm="3" class="left-side">
                    <Form>
                      <Row form>
                        <Col sm="12">
                          <FormGroup>
                            <Label size="lg" for="description">Institution Type</Label>
                            <Input type="select" name="institution" id="institution" maxLength="30"
                                    onChange={updateInput}>
                              <option value="" selected>All</option>)
                              <option value="Bank" selected>Bank</option>)
                            </Input>
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row form>
                        <Col sm="6">
                          <FormGroup>
                            <Label size="lg" for="min-assets">Min Assets</Label>
                            <Input type="text" name="min-assets" id="min-assets" maxLength="30"
                                onChange={updateMinMax} />
                          </FormGroup>
                        </Col>
                        <Col sm="6">
                          <FormGroup>
                            <Label size="lg" for="max-assets">Max Assets</Label>
                            <Input type="text" name="max-assets" id="max-assets" maxLength="30"
                                onChange={updateMinMax} />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row form>
                        <Col sm="12">
                          <FormGroup>
                            <Label size="lg" for="charter">Charter/FDIC</Label>
                            <Input type="text" name="charter" id="charter" maxLength="30"
                                    onChange={updateInput} />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row form>
                        <Col sm="12">
                          <FormGroup>
                            <Label size="lg" for="state">State</Label>
                            <Input type="text" name="state" id="state" maxLength="30"
                                    onChange={updateInput} />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row form>
                        <Col sm="12">
                          <FormGroup>
                            <Label size="lg" for="city">City</Label>
                            <Input type="text" name="city" id="city" maxLength="30"
                                    onChange={updateInput} />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row form>
                        <Col sm="6">
                          <FormGroup>
                            <Label size="lg" for="min-established">Min Established</Label>
                            <Input type="text" name="min-established" id="min-established" maxLength="30"
                                    onChange={updateMinMax} />
                          </FormGroup>
                        </Col>
                        <Col sm="6">
                          <FormGroup>
                            <Label size="lg" for="max-established">Max Established</Label>
                            <Input type="text" name="max-established" id="max-established" maxLength="30"
                                    onChange={updateMinMax} />
                          </FormGroup>
                        </Col>
                      </Row>
                    </Form>
                  </Col>

                  <Col sm="9" className="padding-right-0">
                    <PeerGroupSearchTable confirmedPeerGroups={confirmedPeerGroups} type="search" setSelections={props.setSelections} updateTable={updateTable} setDataTable={setDataTable}></PeerGroupSearchTable>
                  </Col>
                </Row>
              </Container>
            </>
            )}
            </ModalBody>
            <ModalFooter>
              <Button color="secondary" onClick={props.handleClose} size="md">Cancel</Button>
              <Button color="primary" onClick={saveSelections} size="md" disabled={!props.selections.length}>
                Add Selected to Group
              </Button>
            </ModalFooter>
        </div>
    </Modal>);
}

export default PeerGroupSearchModal;