import {
 Modal, ModalHeader, ModalBody, ModalFooter,
  Button, Container, Row, Col, Form, FormGroup, Label, Input
} from 'unify-react';
import { LoadingSpinner } from '@deluxe/unify-loading-spinner';
import React, { useState } from 'react';
import { useNavigate } from "react-router-dom";
import { API_ROOT } from '../ApiConfig/apiConfig';
import _ from 'lodash';
import axios from 'axios';

function ProjectionFormModal(props) {
  const bankId = props.bankId
  let projectId = props.projectId;
  const maxDescriptionLength = 30;
  const copyDescriptionSuffix = " - Copy";
  const maxCopyDescriptionLength = maxDescriptionLength - copyDescriptionSuffix.length;
  const defaultYear = new Date().getFullYear();
  const newProjection = projectId == null || projectId == undefined;
  const token = window.token;
  const username = window.username;
  const projectionEndpoint = `${API_ROOT.projectionEndpoint}/api/projections/${bankId}/project`;
  const createProjectionEndpoint = `${API_ROOT.projectionEndpoint}/api/projections/${bankId}`;
  const apiOptions = { headers: { 'Authorization': `Bearer ${token}` } };
  const navigate = useNavigate();
  const [data, setData] = useState(
    {
      isLoading: !newProjection,
      isNew: newProjection,
      editable: newProjection,
      projection: newProjection ? {
        id: 0,
        description: '',
        startYear: defaultYear,
        numberOfYears: 1,
        isPublic: false,
      } : null
    });

  const isCopyProjection = () => {
    return props.formType.toLowerCase() === "copy";
  }

  const isEditProjection = () => {
    return props.formType.toLowerCase() === "edit";
  }
  const isValidProjection = projection => {
    return projection
      && !_.isNil(projection.id) && projection.id > -1
      && !_.isNil(projection.description) && projection.description.length > 0
      && !_.isNil(projection.numberOfYears) && projection.numberOfYears > 0
      && !_.isNil(projection.isPublic);
  }

  const updateProjectionForm = (event) => {
    let projectionCopy = _.cloneDeep(data.projection);
    const { name, value, type, id } = event.target;
    
    // Handle radio button change
    if (type === 'radio') {
      projectionCopy[name] = JSON.parse(value);
    } else {
      // Handle numberOfYears with validation
      if (id === 'numberOfYears') {
        let parsedInt = parseInt(value);
        if (!isNaN(parsedInt) && parsedInt >= 1 && parsedInt <= 3) {
          projectionCopy.numberOfYears = parsedInt;
        }
      } else {
        projectionCopy[name] = value;
      }
    }

    setData({ projection: projectionCopy, editable: true, isNew: data.isNew });
  }

  const handleNumberOfYearsKeyDown = (event) => {
    // Prevent non-numeric input
    const allowedKeys = ['Backspace', 'ArrowLeft', 'ArrowRight', 'Delete'];
    if ((event.key < '1' || event.key > '3') && !allowedKeys.includes(event.key)) {
      event.preventDefault();
    }
  }

  const fetchProjectionApi = async () => {
    let isNew = false;
    let isEditable = false;
    try {
      const result = await axios.get(`${projectionEndpoint}/${projectId}`, apiOptions);
      if (result.data && isCopyProjection()) {
        if (result.data.description.length > maxCopyDescriptionLength) {
          result.data.description = result.data.description.substr(0, maxCopyDescriptionLength);
        }
        result.data.description = `${result.data.description}${copyDescriptionSuffix}`;
        result.data.startYear = defaultYear;
        result.data.numberOfYears = '';
        isNew = true;
        isEditable = true;
      }
      setData({ isLoading: false, editable: result.data.username === username || isEditable, isNew: isNew, projection: result.data });
      props.setIsEditable(result.data.username === username || isEditable)
    } catch (error) {}
  };

  const updateProjectionApi = async () => {
    setData({ isLoading: true, editable: false });
    await axios.put(`${projectionEndpoint}/${projectId}`, data.projection, apiOptions)
      .then(async () => {
        if (props.handleUpdate) {
          props.handleUpdate()
        }
        props.handleClose();
        if (isEditProjection()) {
          navigate(`/projection/${bankId}/${projectId}/data`);
        }
      }).catch(error =>{})
  }

  const createProjectionApi = async () => {
    setData({ isLoading: true });
    await axios.post(createProjectionEndpoint, data.projection, apiOptions)
      .then(response => {
        projectId = response.data;
        props.handleClose();
          navigate(`/projection/${bankId}/${projectId}/data`);
      }).catch(error => {});
  }

  const saveProjection = async (event) => {
    event.preventDefault();
    if (isValidProjection(data.projection)) {
      if (data.isNew || data.projection.id === 0) {
        await createProjectionApi();
      } else {
        await updateProjectionApi();
      }
    } else {
      console.log("not valid projection");
    }
  }

  const handleOpen = () => {
    switch (props.formType.toLowerCase()) {
      case "create":
        setData({
          isLoading: false,
          isNew: true,
          editable: true,
          projection: newProjection ? {
            id: 0,
            description: '',
            startYear: defaultYear,
            numberOfYears: 1,
            isPublic: false,
          } : null
        });
        break;
      case "copy":
      case "edit":
        setData({ isLoading: true });
        fetchProjectionApi();
        break;
    }
  }

  return (<Modal isOpen={props.show} toggle={props.handleClose} onOpened={handleOpen} tabIndex="-1" role="dialog" size="md">
    <div className="modal-content modal-accent-brand">
      <ModalHeader toggle={props.handleClose}>
        <span className="modal-override-header">
          {`${props.formType} Projection`}
        </span>
      </ModalHeader>
      <ModalBody>
        {data.isLoading ? (<LoadingSpinner isActive={data.isLoading} />) : (
          <>
            <Container>
              <Form>
                <Row form>
                  <Col sm="9">
                    <FormGroup>
                      <Label size="lg" for="description">Description</Label>
                      <Input type="text" name="description" id="description" maxLength="30" disabled={!data.editable}
                        value={data.projection.description} onChange={updateProjectionForm} />
                    </FormGroup>
                  </Col>
                </Row>
                <FormGroup tag="fieldset">
                  <legend className="modal-form-label">Access Level</legend>
                  <FormGroup check inline disabled={!data.editable}>
                    <Label check>
                      <Input type="radio" name="isPublic" onChange={updateProjectionForm} value="true" disabled={!data.editable} checked={data.projection.isPublic === true} />
                      <span className="form-check-element"></span>
                      <span className="form-check-label">Public</span>
                    </Label>
                  </FormGroup>
                  <FormGroup check inline disabled={!data.editable}>
                    <Label check>
                      <Input type="radio" name="isPublic" onChange={updateProjectionForm} value="false" disabled={!data.editable} checked={data.projection.isPublic === false} />
                      <span className="form-check-element"></span>
                      <span className="form-check-label">Private</span>
                    </Label>
                  </FormGroup>
                </FormGroup>
                {data.isNew ?
                  <FormGroup>
                    <Label size="lg" for="startYear">Start Year</Label>
                    <Input name="startYear" plaintext value={defaultYear} readOnly />
                  </FormGroup>
                  : ("")}
                {data.isNew ?
                  <Row form>
                    <Col sm="4">
                      <FormGroup>
                        <Label size="lg" for="numberOfYears">Number Of Years</Label>
                        <Input type="number" name="numberOfYears" id="numberOfYears"
                          maxLength="1" min="1" max="9" value={data.projection.numberOfYears}
                          onChange={updateProjectionForm} />
                      </FormGroup>
                    </Col>
                  </Row>
                  : ("")}
                {!data.isNew ?
                  <Row form>
                    <Col sm="6">
                      <FormGroup>
                        <Label size="lg">Years</Label>
                        <p>
                          <b>{data.projection.startYear}</b> to <b>{data.projection.startYear + data.projection.numberOfYears - 1}</b>
                        </p>
                      </FormGroup>
                    </Col>
                  </Row>
                  : ("")}
              </Form>
            </Container>
          </>
        )}
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={props.handleClose} size="md">Cancel</Button>
        {data.editable ?
          (<Button color="primary" onClick={saveProjection} size="md">
            {data.isNew && data.projection.id > 0 ? "Save Copy" : "Save"}
          </Button>)
          : ("")}
      </ModalFooter>
    </div>
  </Modal>
  );
}

export default ProjectionFormModal;