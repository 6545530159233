const EthosTheme = {
  name: 'Ethos',
  title: 'Financial View',
  custom: {
    navLinkUnderline: '#285bc5',
    navLinkColor: '#023a4d',
    backgroundColor: '#f2ece6',
    backgroundImagePath: 'FISBackground.png',
    headerLogoImg: 'fis-logo.png'
  },
  colors: {
    // YOU CAN CHANGE THESE COLORS
    primary: '#023a4d',
    secondary: '#000f0c',
    brandAccent: '#023a4d',
    accent1: '#b8dade',
    accent2: '#faef69',
    globalHeaderIcons: '#ffffff',
    globalHeaderLinkText: '#ffffff',
    buttonText: '#ffffff',
    buttonHoverBG: '#044e66',
    buttonFocusBG: '#044e66',
    buttonFocusShadow: '#87aade',
    // YOU CAN'T CHANGE THESE COLORS
    neutralWhite: '#ffffff',
    neutralBackground: '#f7f7f7',
    neutralLight: '#e0e0e0',
    neutralBgGrid: '#efefef',
    neutralMediumLight: '#f4f4f4',
    neutralMediumDarker: '#023a4d',
    neutralMedium: '#929292',
    neutralMediumDarkest: '#023a4d',
    neutralDark: '#333333',
    neutralBlack: '#000000',
    alertError: '#f62942',
    alertWarning: '#dfc700',
    alertConfirm: '#058256',
    alertInfo: '#2769cb',
    alertErrorShade: '#fff3f4',
    alertWarningShade: '#fff8ea',
    alertConfirmShade: '#f1f8ea',
    alertInfoShade: '#edf5ff',
    border: '#cccccc'
  },
  fonts: ['SourceSansPro-Black', 'SourceSansPro-SemiBold', 'SourceSansPro-Bold', 'SourceSansPro-Regular'],
  font: {
    main: {
      regular: 'SourceSansPro-Regular',
      light: 'SourceSansPro-Regular',
      bold: 'SourceSansPro-Bold'
    },
    secondary: {
      regular: 'andale-mono-std',
      boldd: 'andale-mono-std-bold'
    },
    weight: {
      light: 300,
      normal: 400,
      bold: 500,
      bolder: 600
    },
    size: {
      base: '1rem',
      utilNav: '0.75rem'
    }
  },
  hTags: {
    h1: {
      fontSize: '3rem',
      lineHeight: '4.5rem',
      color: '#023A4d'
    },
    h2: {
      fontSize: '2.25rem',
      lineHeight: '3.375rem',
      color: '#015B7E'
    },
    h3: {
      fontSize: '2rem',
      lineHeight: '3rem',
      color: '#015B7E'
    },
    h4: {
      fontSize: '1.5rem',
      lineHeight: '2.25rem',
      color: '#015B7E'
    },
    h5: {
      fontSize: '1.25rem',
      lineHeight: '1.875rem',
      color: '#015B7E'
    },
    h6: {
      fontSize: '1.125rem',
      lineHeight: '1.6875rem',
      color: '#015B7E'
    }
  },
  gridBreakpoints: {
    xs: '0',
    sm: '576px',
    md: '768px',
    lg: '992px',
    xl: '1200px'
  },
  border: {
    radius: '0.188rem'
  }
};

module.exports = EthosTheme;