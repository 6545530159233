export default [
  {
    institution_name: "Tiger",
    assets: "$320,800",
    city: "Edinburgh",
    state: 'GA',
    charter: "5421",
    id: 1
  },
  {
    institution_name: "Garrett",
    assets: "$170,750",
    city: "Tokyo",
    state: 'GA',
    charter: "8422",
    id: 2
  },
  {
    institution_name: "Ashton",
    assets: "$86,000",
    city: "San Francisco",
    state: 'GA',
    charter: "1562",
    id: 3
  },
  {
    institution_name: "Cedric",
    assets: "$433,060",
    city: "Edinburgh",
    state: 'GA',
    charter: "6224",
    id: 4
  },
  {
    institution_name: "Airi",
    assets: "$162,700",
    city: "Tokyo",
    state: 'GA',
    charter: "5407",
    id: 5
  },
  {
    institution_name: "Brielle",
    assets: "$372,000",
    city: "New York",
    state: 'GA',
    charter: "4804",
    id: 6
  },
  {
    institution_name: "Herrod",
    assets: "$137,500",
    city: "San Francisco",
    state: 'GA',
    charter: "9608",
    id: 7
  },
  {
    institution_name: "Rhona",
    assets: "$327,900",
    city: "Tokyo",
    state: 'GA',
    charter: "6200",
    id: 8
  },
  {
    institution_name: "Colleen",
    assets: "$205,500",
    city: "San Francisco",
    state: 'GA',
    charter: "2360",
    id: 9
  },
  {
    institution_name: "Sonya",
    assets: "$103,600",
    city: "Edinburgh",
    state: 'GA',
    charter: "1667",
    id: 10
  },
  {
    institution_name: "Jena",
    assets: "$90,560",
    city: "London",
    state: 'GA',
    charter: "3814",
    id: 11
  },
  {
    institution_name: "Quinn",
    assets: "$342,000",
    city: "Edinburgh",
    state: 'GA',
    charter: "9497",
    id: 12
  },
  {
    institution_name: "Charde",
    assets: "$470,600",
    city: "San Francisco",
    state: 'CA',
    charter: "6741",
    id: 13
  },
  {
    institution_name: "Haley",
    assets: "$313,500",
    city: "London",
    state: 'GA',
    charter: "3597",
    id: 14
  },
  {
    institution_name: "Tatyana",
    assets: "$385,750",
    city: "London",
    state: 'GA',
    charter: "1965",
    id: 15
  },
  {
    institution_name: "Michael",
    assets: "$198,500",
    city: "London",
    state: 'GA',
    charter: "1581",
    id: 16
  },
  {
    institution_name: "Paul",
    assets: "$725,000",
    city: "New York",
    state: 'GA',
    charter: "3059",
    id: 17
  },
  {
    institution_name: "Gloria",
    assets: "$237,500",
    city: "New York",
    state: 'GA',
    charter: "1721",
    id: 18
  },
  {
    institution_name: "Bradley",
    assets: "$132,000",
    city: "London",
    state: 'GA',
    charter: "2558",
    id: 19
  },
  {
    institution_name: "Dai",
    assets: "$217,500",
    city: "Edinburgh",
    state: 'GA',
    charter: "2290",
    id: 20
  },
  {
    institution_name: "Jenette",
    assets: "$345,000",
    city: "New York",
    state: 'GA',
    charter: "1937",
    id: 21
  },
  {
    institution_name: "Yuri",
    assets: "$675,000",
    city: "New York",
    state: 'GA',
    charter: "6154",
    id: 22
  },
  {
    institution_name: "Caesar",
    assets: "$106,450",
    city: "New York",
    state: 'NY',
    charter: "8330",
    id: 23
  },
  {
    institution_name: "Doris",
    assets: "$85,600",
    city: "Sidney",
    state: 'GA',
    charter: "3023",
    id: 24
  },
  {
    institution_name: "Angelica",
    assets: "$1,200,000",
    city: "London",
    state: 'GA',
    charter: "5797",
    id: 25
  },
  {
    institution_name: "Gavin",
    assets: "$92,575",
    city: "Edinburgh",
    state: 'GA',
    charter: "8822",
    id: 26
  },
  {
    institution_name: "Jennifer",
    assets: "$357,650",
    city: "Singapore",
    state: 'GA',
    charter: "9239",
    id: 27
  },
  {
    institution_name: "Brenden",
    assets: "$206,850",
    city: "San Francisco",
    state: 'CA',
    charter: "1314",
    id: 28
  },
  {
    institution_name: "Fiona",
    assets: "$850,000",
    city: "San Francisco",
    state: 'CA',
    charter: "2947",
    id: 29
  },
  {
    institution_name: "Shou",
    assets: "$163,000",
    city: "Tokyo",
    state: 'GA',
    charter: "8899",
    id: 30
  },
  {
    institution_name: "Michelle",
    assets: "$95,400",
    city: "Sidney",
    state: 'GA',
    charter: "2769",
    id: 31
  },
  {
    institution_name: "Suki",
    assets: "$114,500",
    city: "London",
    state: 'GA',
    charter: "6832",
    id: 32
  },
  {
    institution_name: "Prescott",
    assets: "$145,000",
    city: "London",
    state: 'GA',
    charter: "3606",
    id: 33
  },
  {
    institution_name: "Gavin",
    assets: "$235,500",
    city: "San Francisco",
    state: 'CA',
    charter: "2860",
    id: 34
  },
  {
    institution_name: "Martena",
    assets: "$324,050",
    city: "Edinburgh",
    state: 'GA',
    charter: "8240",
    id: 35
  },
  {
    institution_name: "Unity",
    assets: "$85,675",
    city: "San Francisco",
    state: 'GA',
    charter: "5384",
    id: 36
  },
  {
    institution_name: "Howard",
    assets: "$164,500",
    city: "San Francisco",
    state: 'GA',
    charter: "7031",
    id: 37
  },
  {
    institution_name: "Hope",
    assets: "$109,850",
    city: "San Francisco",
    state: 'GA',
    charter: "6318",
    id: 38
  },
  {
    institution_name: "Vivian",
    assets: "$452,500",
    city: "San Francisco",
    state: 'GA',
    charter: "9422",
    id: 39
  },
  {
    institution_name: "Timothy",
    assets: "$136,200",
    city: "London",
    state: 'GA',
    charter: "7580",
    id: 40
  },
  {
    institution_name: "Jackson",
    assets: "$645,750",
    city: "New York",
    state: 'GA',
    charter: "1042",
    id: 41
  },
  {
    institution_name: "Olivia",
    assets: "$234,500",
    city: "Singapore",
    state: 'GA',
    charter: "2120",
    id: 42
  },
  {
    institution_name: "Bruno",
    assets: "$163,500",
    city: "London",
    state: 'GA',
    charter: "6222",
    id: 43
  },
  {
    institution_name: "Sakura",
    assets: "$139,575",
    city: "Tokyo",
    state: 'GA',
    charter: "9383",
    id: 44
  },
  {
    institution_name: "Thor",
    assets: "$98,540",
    city: "New York",
    state: 'NY',
    charter: "8327",
    id: 45
  },
  {
    institution_name: "Finn",
    assets: "$87,500",
    city: "San Francisco",
    state: 'CA',
    charter: "2927",
    id: 46
  },
  {
    institution_name: "Serge",
    assets: "$138,575",
    city: "Singapore",
    state: 'GA',
    charter: "8352",
    id: 47
  },
  {
    institution_name: "Zenaida",
    assets: "$125,250",
    city: "New York",
    state: 'GA',
    charter: "7439",
    id: 48
  },
  {
    institution_name: "Zorita",
    assets: "$115,000",
    city: "San Francisco",
    state: 'GA',
    charter: "4389",
    id: 49
  },
  {
    institution_name: "Jennifer",
    assets: "$75,650",
    city: "Edinburgh",
    state: 'GA',
    charter: "3431",
    id: 50
  },
  {
    institution_name: "Cara",
    assets: "$145,600",
    city: "New York",
    state: 'GA',
    charter: "3990",
    id: 51
  },
  {
    institution_name: "Hermione",
    assets: "$356,250",
    city: "London",
    state: 'GA',
    charter: "1016",
    id: 52
  },
  {
    institution_name: "Lael",
    assets: "$103,500",
    city: "London",
    state: 'GA',
    charter: "6733",
    id: 53
  },
  {
    institution_name: "Jonas",
    assets: "$86,500",
    city: "San Francisco",
    state: 'GA',
    charter: "8196",
    id: 54
  },
  {
    institution_name: "Shad",
    assets: "$183,000",
    city: "Edinburgh",
    state: 'GA',
    charter: "6373",
    id: 55
  },
  {
    institution_name: "Michael",
    assets: "$183,000",
    city: "Singapore",
    state: 'GA',
    charter: "5384",
    id: 56
  },
  {
    institution_name: "Donna",
    assets: "$112,000",
    city: "New York",
    state: 'GA',
    charter: "4226",
    id: 57
  }
]
