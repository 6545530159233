import React, { useState } from 'react';
import { useEffect } from 'react';
import { Alert } from 'unify-react';
import styles from './errorPage.module.css';

function Error(props) {
    const closeAlert = () => {
        props.setErrorCode(null);
    };

    useEffect(() => {
        props.setAppVisibility('hide-app');
        props.setWrapperClass('error-page');
        return function cleanup () {
            props.setWrapperClass('');
       };
    }, []);

    
    if (!!props.errorCode && props.errorCode !== 404) {            
        return (
            <div className={styles.ErrorPage + " "+ styles.error_background}>
                <div className={styles.alertContent}>
                    <Alert color="error" className="container-md" isOpen={!!props.errorCode} toggle={closeAlert}>
                    Unexpected error has occurred, please try later
                    </Alert>
                </div>
            </div>
        );
    }
    
}


export default Error;