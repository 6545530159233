import React, { useState, useEffect } from 'react';
import {
    Row, Col, Panel, PanelBody, PanelHeader,
    PanelFooter, Button, Nav, NavItem, NavLink, TabContent, TabPane, Container
} from 'unify-react';
import { LoadingSpinner } from '@deluxe/unify-loading-spinner';
import UserGeneralTab from './userGeneralTab';
import EntityAccessTab from './entityAccessTab';
import EntitySettingsTab from './entitySettingsTab';
import ApplicationAccessTab from './applicationAccessTab';
import { formatPhoneNumber } from '../Shared/Utilities/userUtils';
import axios from 'axios';
import _, { indexOf } from 'lodash';
import classnames from 'classnames';
import { API_ROOT } from '../ApiConfig/apiConfig';
import { USER_TABS } from './userConstants';
import { Link, useParams, useNavigate } from "react-router-dom";
import { isPartner } from '../Shared/Utilities/userUtils';
import { PERMISSIONS } from '../Shared/permissions';
import './user.css';

//can't use within const Usertabs as it gets overwritten
let validatedState = {
    'generalInfo': false,
    'entityAccess': false,
    'entitySettings': true,
    'applicationAccess': true,
}

let entities = [];
let entitiesList = [];
let copiedPerson = null;
let isFISEntry = true;


function User(props) {
    const params = useParams();
    const id = params.id;
    const isAddMode = id === undefined || id === 0;
    const navigate = useNavigate();
    const apiOptions = { headers: { 'Authorization': `Bearer ${window.token}` } };
    const [activeTab, setActiveTab] = useState('generalInfo');
    const [isLoading, setLoading] = useState(true);
    const [isSaving, setSaving] = useState(false);
    const [canProceed, setProceed] = useState(false);
    const [user, setUser] = useState({
        name: '',
        customerId: 0,
        username: '',
        firstName: '',
        lastName: '',
        entities: [],
        enabled: true,
        permissions: [],
        fdic: window.fdic,
        companyName: '',
        location: '',
        email: '',
        phone: '',
        emailOnUpdate: true,
        role: 2,
        id: 0,
        partnerId: ''
    });

    const updateUser = (userSent, valid, tabType) => {
        let tab = userTabs.find(x => x.type === tabType);
        validatedState[tab.type] = !!valid;
        if (valid || tabType === 'generalInfo') {
            let userCopy = _.cloneDeep(userSent);
            setUser(userCopy);
        }

        if (activeTab === tabType) {
            setProceed(valid);
        }
    };

    const setEntities = (ents) => {
        entities = { ...entities, ...ents };
    };

    const setTab = (direction) => {
        let currentTabIndex = userTabs.findIndex(x => x.type === activeTab);
        let tab;
        if (direction === 'next') {
            tab = userTabs[currentTabIndex + 1];
        } else {
            tab = userTabs[currentTabIndex - 1];
        }
        setActiveTab(tab.type);
    };
    

    const getIsSSO = async (username) => {
        try {
            let response = await axios.get(`${API_ROOT.clientEndpoint}/api/users/${username}/sso`, apiOptions)
            return response.data;
        } catch (e) {
            console.log('handle sso error')
            return false;
        }
    }

    const mapUser = (user, entitiesList) => {
        let userCopy = _.cloneDeep(user);
        userCopy.username = cleanUsername(user.username);
        userCopy.phone = formatPhoneNumber(user.phone);
        userCopy = setupUserEntities(userCopy);
        return userCopy;
    }

    const setupUserEntities = (userCopy) => {
        userCopy.entities.forEach((entity) => {
            let entResult = entitiesList.find((entResult) => entResult.id === entity.bankId)
            entity.name = entResult.name;
            entity.listOrder = entResult.listOrder;
        })
        userCopy.entities = userCopy.entities.sort((a, b) => a.listOrder - b.listOrder);
        return userCopy;
    }

    const copyUser = async (event) => {
        copiedPerson = event.target.value;
        let userCopy = _.cloneDeep(user);
        if (!!copiedPerson) {
            try {
                let result = await axios.get(`${API_ROOT.userEndpoint}/api/${window.fdic}/users/${copiedPerson.split('.')[2]}`, apiOptions);
                userCopy.entities = result.data.entities;
                userCopy.permissions = result.data.permissions;
                userCopy.entities.forEach((entity) => {
                    let entResult = entitiesList.find((entResult) => entResult.id === entity.bankId)
                    entity.name = entResult.name;
                    entity.listOrder = entResult.listOrder;
                })
                userCopy = setupUserEntities(userCopy);
            } catch (error) { }
        } else {
            copiedPerson = null;
            userCopy.entities = [];
            userCopy.permissions = [];
        }
        setUser(userCopy);
        updateUser(userCopy, !!userCopy.entities.length, USER_TABS.entityAccess);
    }

    const cleanUsername = (username) => {
        const usernameParts = username.split('.');
        usernameParts.splice(usernameParts.length - 1, 1);
        return usernameParts.join('.');
    }

    const cleanPhoneNumber = (phone) => {
        return ('' + phone).replace(/\D/g, '');
    }

    const mergePermissions = (dashboardPermissions, sessionPermissions) => {
        if(sessionPermissions.length == 0){
            return dashboardPermissions.filter(permission => permission != PERMISSIONS.UserAdmin);
        }
        else if(sessionPermissions.includes(PERMISSIONS.UserAdmin) && !dashboardPermissions.includes(PERMISSIONS.UserAdmin)){
            return [...dashboardPermissions, PERMISSIONS.UserAdmin];
        }
        return dashboardPermissions;
    }

    const saveUser = async () => {
        try {
            setSaving(true);
            user.phone = cleanPhoneNumber(user.phone);
            if (isAddMode) {
                let url = copiedPerson ? `${API_ROOT.userEndpoint}/api/${window.fdic}/users` + '?sourceId=' + copiedPerson : `${API_ROOT.userEndpoint}/api/${window.fdic}/users`
                await axios.post(url, user, apiOptions);
            } else {
                await axios.put(`${API_ROOT.userEndpoint}/api/${window.fdic}/users/${id}`, user, apiOptions);
            }
            setSaving(false);
            navigate(`/userlist`);
        } catch (error) {
            setSaving(false);
        }
    }

    const fetchData = async (id) => {
        try {
            entitiesList = await axios.get(
                `${API_ROOT.fdicEndpoint}/api/Fdic/${window.fdic}/entities-list`, apiOptions
            );
            entitiesList = entitiesList.data;

            const fisUserSession = JSON.parse(sessionStorage.getItem('bankersSessionUser'));
            let userCopied = {};
            
            if (fisUserSession) {
                userCopied = { ...fisUserSession };
                sessionStorage.removeItem('bankersSessionUser');
            }

            if (!isAddMode) {
                const result = await axios.get(`${API_ROOT.userEndpoint}/api/${window.fdic}/users/${id}`, apiOptions);
                if(userCopied !== null && fisUserSession){
                   userCopied.permissions =  mergePermissions(result.data.permissions, userCopied.permissions);
                }
                userCopied = {
                    ...mapUser(result.data, entitiesList), 
                    ...userCopied, 
                    isSSO: await getIsSSO(result.data.username),
                    isPartner: isPartner(),
                };
       
                setUser(userCopied);
            }
            else if (isAddMode && fisUserSession) {
                userCopied = { ...user, ...userCopied };
                setUser(userCopied);
            }
            setLoading(false);
        } catch (error) {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchData(id);
    }, []);

    useEffect(() => {
        setProceed(validatedState[activeTab]);
    }, [activeTab]);

    const userTabs = [
        { type: USER_TABS.generalInfo, name: 'General Info', disabled: false, component: (<UserGeneralTab copyUser={copyUser} user={user} onUpdate={updateUser} />) },
        { type: USER_TABS.entityAccess, name: 'Entity Access', entitiesList: { entitiesList }, disabled: { isAddMode }, component: (<EntityAccessTab active={activeTab === 'entityAccess'} user={user} setEntities={setEntities} onUpdate={updateUser} entitiesList={entitiesList} />) },
        { type: USER_TABS.entitySettings, name: 'Entity Settings', disabled: { isAddMode }, component: (<EntitySettingsTab user={user} active={activeTab === 'entitySettings'} onUpdate={updateUser} />) },
        { type: USER_TABS.applicationAccess, name: 'Application Access', disabled: { isAddMode }, component: (<ApplicationAccessTab user={user} active={activeTab === 'applicationAccess'} onUpdate={updateUser} />) }
    ];


    return (
        <>
            <Container className={isSaving ? 'disabled' : ''}>
                {isLoading ? (<LoadingSpinner isActive={isLoading} />) : (
                    <>
                        <Row className="no-gutters table-header" xs="1" sm="2">
                            <Col>
                                <h1 className='app-title-h1'>{isAddMode ? 'Add New User' : 'Edit User'}</h1>
                            </Col>
                        </Row>
                        <Row>
                            <Col sm="12">
                                <Panel className="tab-panel">
                                    <PanelHeader className="tab-panel-header">
                                        <Nav className="tab-nav" tabs id="user-tabs">
                                            {userTabs.map(({ type, name, disabled }) => (
                                                <NavItem className='tab-user' key={`${type}-nav`}>
                                                    <NavLink disabled={(disabled && isAddMode) || !canProceed} className={`${classnames({ active: activeTab === type })} tab-links`} id={type} onClick={() => setActiveTab(type)}>
                                                        {name}
                                                    </NavLink>
                                                </NavItem>
                                            ))}
                                        </Nav>
                                    </PanelHeader>
                                    <PanelBody>
                                        <TabContent activeTab={activeTab}>
                                            {userTabs.map(({ type, name, component }) => (
                                                <TabPane tabId={type} key={`${type}-pane`}>
                                                    {component}
                                                </TabPane>
                                            ))}
                                        </TabContent>
                                    </PanelBody>

                                    <PanelFooter className="tab-panel-footer">
                                        <Row>
                                            <Col xs="6"><Link tabIndex="98" to="/userlist"><Button variant="secondary">Cancel</Button></Link>

                                            </Col>
                                            <Col xs="6" className="d-flex justify-content-end">
                                                {userTabs.findIndex(x => x.type === activeTab) > 0 && (isAddMode || !isAddMode && canProceed) ?
                                                    (<Button tabIndex="100" variant="secondary" onClick={() => setTab('previous')}>Previous</Button>) : (<></>)}
                                                {userTabs.findIndex(x => x.type === activeTab) < userTabs.length - 1 ?
                                                    (<Button tabIndex="99" disabled={!canProceed} variant="secondary" onClick={() => setTab('next')}>Next</Button>) :
                                                    ''}
                                                {!isAddMode || userTabs.findIndex(x => x.type === activeTab) > 1 ?
                                                    (<Button variant="primary" disabled={isSaving || !canProceed} onClick={saveUser}>Save And Close</Button>) :
                                                    ''}
                                            </Col>
                                        </Row>
                                    </PanelFooter>
                                </Panel>
                            </Col>
                        </Row>
                    </>
                )}
            </Container>
        </>
    );
}

export default User;