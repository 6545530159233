import React, { useState, useEffect } from 'react';
import {Container, Checkbox } from 'unify-react';
import { LoadingSpinner } from '@deluxe/unify-loading-spinner';
import { API_ROOT } from '../ApiConfig/apiConfig';
import { USER_TABS } from './userConstants';
import { Col, Row } from 'unify-react';
import axios from 'axios';

let fetchedData;
function ApplicationAccessTab(props) {
    const [isLoading, setLoading] = useState(true);
    const [permissions, setPermissions] = useState({});

    const userPermissions = useState(props.user.permissions);
    const apiOptions = { headers: { 'Authorization': `Bearer ${window.token}` } };
    const fdic = window.fdic;

    const setupPermissions = (permissions, permissionsToLoad) => {
        let permissionsMap = {};
        permissions.sort((a, b) => a.listOrder - b.listOrder);
        permissions.forEach(function (permission) {
            permission.checked = permissionsToLoad
                ? permissionsToLoad.some((userPermission) => permission.id === userPermission)
                : false;
            permissionsMap[permission.id] = permission;
        });
        return permissionsMap;
    };

    const fetchPermissions = async () => {
        try {
            let results = await axios.get(
                `${API_ROOT.userEndpoint}/api/${fdic}/application-permissions`, apiOptions
            );
            fetchedData = results.data;
            setLoading(false);
            setPermissions(setupPermissions(fetchedData, props.user.permissions));
        } catch (error) {
            setLoading(false);
        }
    };

    const toggleCheckbox = (key) => {
        setPermissions((permissions) => {
            const newPermissions = { ...permissions };
            newPermissions[key].checked = !permissions[key].checked;
            return newPermissions;
        });
    };

    const formatPermissions = () => {
        let permissionsChecked = [];
        Object.entries(permissions).forEach(([key, permission]) => {
            if (permission.checked && permission.id) {
                permissionsChecked.push(permission.id);
            }
        });
        return permissionsChecked;
    };

    useEffect(() => {
        if (isLoading) {
            fetchPermissions();
        } else {
            let permissionsChecked = formatPermissions();
            props.user.permissions = permissionsChecked;
            props.onUpdate(props.user, true, USER_TABS.applicationAccess);
        };
    }, [permissions]);

    useEffect(() => {
        if (fetchedData) {
            setPermissions(setupPermissions(fetchedData, props.user.permissions));
        }
    }, [props.active]);
    return (<> {
        isLoading ? (<LoadingSpinner isActive={isLoading} />) : (
            <Container className="mx-4 pl-4 application-access-tab">
                <Row>
                    <Col className="mb-3">
                        <h5 className='d-i-b pr-4 mr-2'>
                            Application Access
                        </h5>
                    </Col>
                </Row>
                {Object.entries(permissions).map(([key, permission]) =>
                    <Checkbox data-testid={`cb-${permission.id}`} key={permission.id} onChange={() => toggleCheckbox(key)} checked={permission.checked} label={permission.name} />
                )}
            </Container>
        )} </>
    );
}

export default ApplicationAccessTab;