import React from "react";

const FeatureUnavailable = (props) => {
  return (
    <div>
      <h3 style={{textAlign: "middle"}}>
        {props.feature} is not available. Switch to our premium plan to get access
        to this and many other amazing features
      </h3>
    </div>
  );
};

export default FeatureUnavailable;
