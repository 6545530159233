export const BD_MENU = [
  {
    id: "dashboards",
    title: "Dashboards",
    icon: "information",
    submenu: [
      {
        id: "summary",
        title: "Summary",
        parentId: "dashboards",
        to: "",
        icon: "",
      },
      {
        id: "monthly-dashboard",
        title: "Monthly Dashboard",
        parentId: "dashboards",
        to: "",
        icon: "reports",
      },
      {
        id: "quarterly-dashboard",
        title: "Quarterly Dashboard",
        parentId: "dashboards",
        to: "",
        icon: "",
      },
    ],
  },
  {
    id: "gl",
    title: "G/L",
    icon: "information",
    submenu: [
      {
        id: "summaries",
        title: "Summaries",
        parentId: "gl",
        to: "",
        icon: "home",
        /*submenu: [
          {
            id: "executive-summary",
            title: "Executive Summary",
            parentId: "summaries",
            to: "",
            icon: "",
          },
          {
            id: "executive-summary-compare",
            title: "Executive Summary Compare",
            parentId: "summaries",
            to: "",
            icon: "",
          },
          {
            id: "executive-summary-12-mos",
            title: "Executive Summary (12 mos.)",
            parentId: "summaries",
            to: "",
            icon: "",
          },
          {
            id: "ratio-analysis",
            title: "Ratio Analysis",
            parentId: "summaries",
            to: "",
            icon: "",
          },
        ],*/
      },
      {
        id: "balance-sheet",
        title: "Balance Sheet",
        parentId: "gl",
        to: "/report/BalanceSheetHistorical#new",
        icon: "reports",
        /*submenu: [
          {
            id: "balance-sheet-compare",
            title: "Balance Sheet Compare",
            parentId: "balance-sheet",
            to: "",
            icon: "",
          },
          {
            id: "balance-sheet-submenu",
            title: "Balance Sheet",
            parentId: "balance-sheet",
            to: "/reportmodal/BalanceSheetHistorical",
            icon: "reports",
          },
          {
            id: "earning-assets-mix",
            title: "Earning Assets Mix",
            parentId: "balance-sheet",
            to: "",
            icon: "reports",
          },
          {
            id: "funding-mix",
            title: "Funding Mix",
            parentId: "balance-sheet",
            to: "",
            icon: "reports",
          },
          {
            id: "non-earning-assets-mix",
            title: "Non-Earning Assets Mix",
            parentId: "balance-sheet",
            to: "",
            icon: "reports",
          },
          {
            id: "loan-concentration",
            title: "Loan Concentration",
            parentId: "balance-sheet",
            to: "",
            icon: "reports",
          },
        ],
        */
      },
      {
        id: "income-statement",
        title: "Income Statement",
        parentId: "gl",
        to: "/report/IncomeStatementHistorical#new",
        icon: "reports",
        /*submenu: [
          {
            id: "income-statement-compare",
            title: "Income Statement Compare",
            parentId: "income-statement",
            to: "",
            icon: "reports",
          },
          {
            id: "income-statement-submenu",
            title: "Income Statement Compare",
            parentId: "income-statement",
            to: "/reportmodal/IncomeStatementHistorical",
            icon: "reports",
          },
          {
            id: "non-interest-income",
            title: "Non-Interest Income",
            parentId: "income-statement",
            to: "",
            icon: "reports",
          },
          {
            id: "non-interest-expense",
            title: "Non-Interest Expense",
            parentId: "income-statement",
            to: "",
            icon: "reports",
          },
        ],*/
      },
      {
        id: "margin-analysis",
        title: "Margin Analysis",
        parentId: "gl",
        to: "",
        icon: "reports",
        /*submenu: [
          {
            id: "margin-analysis-compare",
            title: "Margin Analysis Compare",
            parentId: "margin-analysis",
            to: "",
            icon: "reports",
          },

          {
            id: "margin-analysis-submenu",
            title: "Margin Analysis",
            parentId: "margin-analysis",
            to: "/reportmodal/MarginAnalysisHistorical",
            icon: "reports",
          },

          {
            id: "margin-graph",
            title: "Margin Graph",
            parentId: "margin-analysis",
            to: "",
            icon: "reports",
          },

          {
            id: "margin-components-graph",
            title: "Margin Components Graph",
            parentId: "margin-analysis",
            to: "",
            icon: "reports",
          },
        ],
        */
      },
      {
        id: "capital-and-regulatory",
        title: "Capital & Regulatory",
        parentId: "gl",
        to: "",
        icon: "reports",
        /*submenu: [
          {
            id: "liquidity-report",
            title: "Liquidity Report",
            parentId: "capital-and-regulatory",
            to: "",
            icon: "reports",
          },
          {
            id: "allowance-for-loan-loss-adequacy",
            title: "Allowance for loan Loss Adequacy",
            parentId: "capital-and-regulatory",
            to: "",
            icon: "reports",
          },
          {
            id: "capital-requirements",
            title: "Capital Requirements",
            parentId: "capital-and-regulatory",
            to: "",
            icon: "reports",
          },
          {
            id: "capital-ratios",
            title: "Capital Ratios",
            parentId: "capital-and-regulatory",
            to: "",
            icon: "reports",
          },
          {
            id: "risk-weighted-assets-worksheet",
            title: "Risk Weighted Assets Worksheet",
            parentId: "capital-and-regulatory",
            to: "",
            icon: "reports",
          },
          {
            id: "texas-ratio",
            title: "Texas Ratio",
            parentId: "capital-and-regulatory",
            to: "",
            icon: "reports",
          },
        ],*/
      },
    ],
  },
  {
    id: "loan-cds",
    title: "Loan and CDs",
    to: "",
    icon: "information",
    submenu: [
      {
        id: "loan-cds-summaries",
        title: "Summaries",
        parentId: "loan-cds",
        to: "",
        icon: "",
      },
      {
        id: "loans",
        title: "Loans",
        parentId: "loan-cds",
        to: "",
        icon: "",
      },
      {
        id: "cds",
        title: "CDs",
        parentId: "loan-cds",
        to: "",
        icon: "",
      },
      {
        id: "cd-maturity-rate-change",
        title: "CD Maturity Rate Change",
        parentId: "loan-cds",
        to: "",
        icon: "",
      },
      {
        id: "prime-rate-shock",
        title: "Prime Rate Shock",
        parentId: "loan-cds",
        to: "",
        icon: "",
      }
    ]
  },
  {
    id: "projections",
    title: "Projections",
    to: "",
    icon: "information",
    submenu: [
      {
        id: "projection-submenu",
        title: "Projections",
        parentId: "projections",
        to: "/projectionlist#new",
        icon: "",
      },
      {
        id: "forecasting",
        title: "Forecasting",
        parentId: "projections",
        to: "",
        icon: "",
      },
      {
        id: "upload-template",
        title: "Upload Template",
        parentId: "projections",
        to: "",
        icon: "",
      },
    ],
  },
  {
    id: "report-packages",
    title: "Report Packages",
    to: "",
    icon: "reports",
    submenu: [
      {
        id: "run-report-packages",
        title: "Run Report Packages",
        parentId: "report-packages",
        to: "",
        icon: "",
      },
      {
        id: "setup-report-packages",
        title: "Set Up Report Packages",
        parentId: "report-packages",
        to: "",
        icon: "",
      }
    ],
  },
  {
    id: "branch-performance",
    title: "Branch Performance",
    to: "",
    icon: "information",
    submenu: [
      {
        id: "show-branch-performance",
        title: "Show Branch Performance",
        parentId: "branch-performance",
        to: "",
        icon: "",
      },
      {
        id: "create-new-view",
        title: "Create New View",
        parentId: "branch-performance",
        to: "",
        icon: "",
      },
      {
        id: "manage-existing-view",
        title: "Manage Existing View",
        parentId: "branch-performance",
        to: "",
        icon: "",
      },
      {
        id: "copy-template",
        title: "Copy Template",
        parentId: "branch-performance",
        to: "",
        icon: "",
      }
    ]
  },
  {
    id: "loan-pricing",
    title: "Loan Pricing",
    to: "",
    icon: "information",
    submenu: [
      {
        id: "loan-pricing-model",
        title: "Loan Pricing Model",
        parentId: "loan-pricing",
        to: "",
        icon: "",
      },
      {
        id: "loan-pricing-defaults",
        title: "Defaults",
        parentId: "loan-pricing",
        to: "",
        icon: "",
      },
      {
        id: "risk-weightings",
        title: "Risk Weightings",
        to: "",
        icon: "",
      },
      {
        id: "loan-types",
        title: "Loan Types",
        parentId: "loan-pricing",
        to: "",
        icon: "",
      },
      {
        id: "loan-pricing-spreads",
        title: "Loan Pricing Spreads",
        parentId: "loan-pricing",
        to: "",
        icon: "",
      }
    ]
  },
  {
    id: "tools",
    title: "Tools",
    to: "",
    icon: "information",
    submenu: [
      {
        id: "custom-graphing",
        title: "Custom Graphing",
        parentId: "tools",
        to: "",
        icon: "",
      },
      {
        id: "financial-calculator",
        title: "Financial Calculator",
        parentId: "tools",
        to: "",
        icon: "",
      },
      {
        id: "custom-calculations",
        title: "Custom Calculations",
        parentId: "tools",
        to: "",
        icon: "",
      },
      {
        id: "display-names",
        title: "Display Names",
        parentId: "tools",
        to: "",
        icon: "",
      },
    ],
  },
  {
    id: "settings",
    title: "Settings",
    to: "",
    icon: "information",
    submenu: [
      {
        id: "my-dashboard",
        title: "My Dashboard",
        parentId: "settings",
        to: "",
        icon: "",
      },
      {
        id: "my-centers",
        title: "My Centers",
        parentId: "settings",
        to: "",
        icon: "",
      },
      {
        id: "my-alarts",
        title: "My Alarts",
        parentId: "settings",
        to: "",
        icon: "",
      },
    ],
  },
  {
    id: "admin",
    title: "Admin",
    to: "",
    icon: "information",
    submenu: [
   /*  {
        id: "users",
        title: "Users",
        parentId: "admin",
        to: "/userlist#new",
        icon: "",
      },*/
      {
        id: "gl-mapping",
        title: "G/L Mapping",
        parentId: "admin",
        to: "",
        icon: "",
      },
      {
        id: "loans-cds-mapping",
        title: "Loans & CDs Mapping",
        parentId: "admin",
        to: "",
        icon: "",
      },
      {
        id: "company-settings",
        title: "Company Settings",
        parentId: "admin",
        to: "",
        icon: "",
      },
      {
        id: "manage-data",
        title: "Manage Data",
        parentId: "admin",
        to: "",
        icon: "",
      },
    ],
  },
];

export const PARENT_MENU_ITEMS = {
  projection: "Projections",
  gl: "G/L",
  admin: "Admin",
  loanAndCd: "Loan and CDs",
  reportPackages: "Report Packages",
  branchPerformance: "Branch Performance",
  loanPricing: "Loan Pricing",
  tools: "Tools",
};
