import React, { useEffect } from 'react';
import { Table, Checkbox } from 'unify-react';
import data from "../mock_data/peerDataTable";
import ReactDOMServer from 'react-dom/server';

let peersMapped = {}
function PeerGroupSearchTable(props) {
    //doing this with jquery as tables are jquery but should removed for react when switch to react table
    // return !!window.unify.$('.unify .form-check .form-check-input[type=checkbox]:checked').length;
    let selectionData = props.data || data;
    let tableStored;
    let tableId;
    let confirmedSelections = props.confirmedSelections;
    const checkSelected = () => {
        let selections = window.unify.$('.unify .form-check .form-check-input[type=checkbox]:checked').map(function(ind, e){return e.id});
        return selections.toArray().map(function(id){
            return peersMapped[id];
        })
    }

    const inputHtml = (id) => {
        return (
            <Checkbox id={id} />
        );
    };

    function initializeDataTable() {
        if(confirmedSelections){
            let confirmedSelectionsMapped = {};
            confirmedSelections.forEach(function(peergroup){
                confirmedSelectionsMapped[peergroup.id.toString()] = peergroup;
            });
            selectionData = selectionData.filter(function(peergroup){
                return !confirmedSelectionsMapped.hasOwnProperty(peergroup.id.toString());
            });
        };
        selectionData.forEach(function(peergroup){
            peersMapped[peergroup.id.toString()] = peergroup;
        });
        tableId = `#peer-group-search-table-${props.type}`;
        tableStored = window.unify.$(tableId).DataTable({
            "paging":   false,
            data: selectionData,
            "destroy": true,
            "columnDefs": [ {
                "targets": 0,
                "data": 'input',
                "render": function ( data, type, row, meta ) {
                    return ReactDOMServer.renderToString(inputHtml(row.id));
                }
              } ],
            columns: [
                { data: "" , name: 'input'},
                { data: "charter" , name: 'charter'},
                { data: "institution_name" },
                { data: "city", name: 'city'},
                { data: "state", name: 'state'},
                { data: "assets" }
            ]
        });
        window.unify.$(`${tableId} label .form-check-element`).on('click', function (e) {
            window.setTimeout(function(){
                props.setSelections(checkSelected());
            });
        } );

        props.setDataTable(tableStored);
    }

    function destroy(){
        window.unify.$(`${tableId} label .form-check-element`).unbind('click');
        if(tableStored && tableStored.destroy){
            tableStored.destroy();
        }
    }

    useEffect(() => {
        props.setSelections(checkSelected());
    }, [props.updateTable]);

    useEffect(() => {
        initializeDataTable();
    }, [props.data]);

    useEffect(() => {
        initializeDataTable();

        return () => destroy();
    }, []);

    return (
        <Table id={'peer-group-search-table-' + props.type}>
          <thead>
            <tr>
              <th></th>
              <th>Charter #</th>
              <th>Institution Name</th>
              <th>City</th>
              <th>State</th>
              <th>Assets</th>
            </tr>
          </thead>
        </Table>
    );
}

export default PeerGroupSearchTable;