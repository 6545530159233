import React from 'react';
import { Panel, PanelBody, PanelFooter, Container, Button, Badge, Toggle, FontAwesomeIcon } from 'unify-react';
import { Col, Row } from 'unify-react';
import styles from './userCard.module.css';
import dayjs from 'dayjs';

function UserCard (props) {
    const user = props;
    const loginDate = dayjs(user.lastLoginDate).isValid()
     ? dayjs(user.lastLoginDate).format("MM/DD/YYYY - hh:mma") : '';

    const handleDelete = (e) => {
        e.stopPropagation();
        user.onDelete();
    }

    const handleEditUser = () => {
        user.onEdit();   
    }

    const handleStatusChange = (e) => {
        user.onStatusChange();
    }

    const stopPropagation = (e) => {
        e.stopPropagation();
        return true;
    }

    return (
        <Col xs="3" className={styles.user_card_col}>
            <Panel className={styles.user_card} onClick={(e) => handleEditUser(e)}>
                <PanelBody>
                    <Container className="">
                        <Row>
                            <Col xs="6" className="no-side-padding">
                                <div className={styles.user_card_admin_label}>
                                    {user.role === 1 ? (<Badge className={styles.user_card_badge} color="info">Admin</Badge>) : (<></>)}
                                </div>
                            </Col>
                            <Col xs="6" className="no-side-padding">
                                <div className={`${styles.user_card_toggle_label} float-right`}>
                                    <span className={styles.user_card_label}>Active</span>
                                    <div onClick={(e) => stopPropagation(e)}>
                                        <Toggle id={`status-${user.id}`} checked={user.enabled} small={true} label="" onChange={(e) => handleStatusChange(e)}/>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                        <Row><h5 className={styles.user_card_heading}>{user.lastName}, {user.firstName}</h5></Row>
                        <Row><span className={styles.user_card_subheading}>{user.username}</span></Row>
                        <Row>
                            <span className={`${styles.user_card_label} ${styles.margin_top_10}`}>Last login</span>
                        </Row>
                        <Row>
                            <span className={styles.user_card_subheading}>{loginDate ? loginDate : 'N/A'}</span>
                        </Row>
                    </Container>
                </PanelBody>
                <hr className={styles.user_card_divider} />
                <PanelFooter className={styles.user_card_footer}>
                    <Container>
                        <Row>
                            <Col className="no-side-padding" xs="6">
                                <Button className={styles.user_card_footer_btn} onClick={(e) => handleDelete(e)}>
                                    <FontAwesomeIcon icon={["far", "trash-alt"]} size="1x" />
                                    <span>Delete</span>
                                </Button>
                            </Col>
                            <Col className="no-side-padding" xs="6">
                                <Button className={styles.user_card_footer_btn} disabled>
                                    <FontAwesomeIcon icon={["fas", "chart-bar"]} size="1x" />
                                    <span>Stats</span>
                                </Button>
                            </Col>
                        </Row>
                    </Container>
                </PanelFooter>
            </Panel>
        </Col>
    );
}

export default UserCard;