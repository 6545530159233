import {BANKERS_DOMAINS, SERVICE_DOMAINS } from './domains';

let protocol = "https://";
const bankersHost = window && window.location && window.location.hostname;


const getUrl = (serviceName, env) => {
    return `${protocol}${serviceName}${env}${BANKERS_DOMAINS.Dashboard}`;
}

const getFisClientUrl = (env) => {
    let environment = '';
    if(env !== ''){
         environment = env.substring(1)+".";
    }
    return `${protocol}${environment}${SERVICE_DOMAINS.ClientHostFis}`;
}

//Gets service urls (which is called client service) for xuat and prod
const getUpperEnvServiceUrl = (environment) => {
    switch (environment){
        case '':
           return `${protocol}${SERVICE_DOMAINS.serviceHostProd}${environment}${BANKERS_DOMAINS.Dashboard}`;
        case '-xuat':
            return `${protocol}${SERVICE_DOMAINS.serviceHost}.xuat${BANKERS_DOMAINS.Dashboard}`;
        default:
            return '';
    }
}

export const dashboardApis = () => {
    let apis = {};
    if(!bankersHost.includes(BANKERS_DOMAINS.Fis)){
        let dashIndex =  bankersHost.indexOf("-");
        let dotIndex =  bankersHost.indexOf(".");
        let env = dashIndex > 0 ?  bankersHost.substring(dashIndex, dotIndex) : '';
        apis.projectionEndpoint = getUrl(SERVICE_DOMAINS.projectionHost, env);
        apis.userEndpoint = getUrl(SERVICE_DOMAINS.userHost, env);
        apis.fdicEndpoint = getUrl(SERVICE_DOMAINS.fdicHost, env);
        apis.clientEndpoint = getUrl(SERVICE_DOMAINS.clientHost, env);
        apis.serviceEndpoint = !(env === '' || env === '-xuat') ? getUrl(SERVICE_DOMAINS.serviceHost, env) : getUpperEnvServiceUrl(env);
        apis.glServiceEndpoint = getUrl(SERVICE_DOMAINS.glHost, env);
    }
    return apis;
}

export const partnerApis = () => {
    let apis = {};
    if(bankersHost.includes(BANKERS_DOMAINS.Fis)){
        let dIndex = bankersHost.indexOf(".");
        let environment = dIndex <= 4 ? "-"+bankersHost.substring(0, dIndex) : '';
        apis.projectionEndpoint = getUrl(SERVICE_DOMAINS.projectionHost, environment);
        apis.userEndpoint = getUrl(SERVICE_DOMAINS.userHost, environment);
        apis.fdicEndpoint = getUrl(SERVICE_DOMAINS.fdicHost, environment);
        apis.clientEndpoint = getFisClientUrl(environment);
        apis.serviceEndpoint = (environment !== '' && (environment !== '-xuat')) ? getUrl(SERVICE_DOMAINS.serviceHost, environment) : getUpperEnvServiceUrl(environment);
        apis.glServiceEndpoint = getUrl(SERVICE_DOMAINS.glHost, environment);
    }
    return apis;
}





