import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { DataTable } from "primereact/datatable";
import { InputText } from "primereact/inputtext";
import { Column } from "primereact/column";
import { ColumnGroup } from 'primereact/columngroup';
import {
  Button,
  ButtonDropdown,
  DropdownItem,
  DropdownToggle,
  DropdownMenu,
  Container,
  Row,
  Col,
  Form,
  FormGroup,
} from "unify-react";
import { LoadingSpinner } from "@deluxe/unify-loading-spinner";
import { NumericFormat } from "react-number-format";
import axios from "axios";
import { v4 as uuidv4 } from "uuid";
import { API_ROOT } from "../ApiConfig/apiConfig";
import ProjectionFinancialFooter from "./projectionFinancialFooter";
import ProjectionFormModal from "./projectionFormModal";
import { bankersRound } from "../Shared/bankersRounding";
import "./projections.css";
import { Link } from "react-router-dom";

function ProjectionFinancial(props) {
  const navigate = useNavigate();
  const showNumberOfYears = 4;
  let sectionRowIndexArray = [];
  let columnInputOffSet = 5;
  let projectionDataEndpoint = "";
  let isProjection = false;

  if (props.projectionsShown &&
    props.financialDataType === "projections-bs"
    || props.financialDataType === "projections-is"
    || props.financialDataType === "projections-bsChanges"
    || props.financialDataType === "projections-isChanges"
  ) {
    projectionDataEndpoint = `${API_ROOT.projectionEndpoint}/api/projections/${props.bankId}/project/${props.id}/projections`;
    isProjection = true;
  } else {
    if (props.financialDataType !== "") {
      projectionDataEndpoint = `${API_ROOT.projectionEndpoint}/api/projections/${props.bankId}/project/${props.id}/${props.financialDataType}`;
    }
  }
  const downloadEndpoint = `${API_ROOT.clientEndpoint}/api/projections/generate`;
  const fileEndpoint = `${API_ROOT.clientEndpoint}/Clients/Output/`;
  const apiOptions = { headers: { Authorization: `Bearer ${props.token}` } };
  const [data, setData] = useState({
    isLoading: true,
    financialData: [],
    historicalYear: 0,
    numberOfYears: 1,
    columns: [],
    previousEnabled: false,
    nextEnabled: true,
  });
  const [isSaving, setSaving] = useState(false);
  const [reloadProjection, setReloadProjection] = useState(false);
  const generateUUID = () => {
    return uuidv4();
  };
  const isProjectionReport = (financialDataType) => {
    return financialDataType === "projections-bs" || financialDataType === "projections-is";
  };
  const isProjectionChangesReport = (financialDataType) => {
    return financialDataType === "projections-bsChanges" || financialDataType === "projections-isChanges";
  }
  const rowClasses = (row, rowIndex) => {
    let classes = "align-middle";
    if (row.isSection) classes = "section-header";
    if (row.isHeaderBold) classes = "align-middle  header-bold"
    return classes;
  };
  const showValidationMessage = (message) => {
    alert(message);
  };
  const validateInput = (e) => {
    let {
      originalEvent: event,
      value,
      field,
      rowData,
      rowIndex,
      cellIndex,
      selected,
      column,
      newRowData,
      newValue,
    } = e;
    const isPercentage = newValue.indexOf("%") !== -1;
    const isDollar = newValue.indexOf("$") !== -1;
    //const isDollar = !isPercentage;
    let cleanValue = sanitizeNumber(newValue);
    if (isNaN(cleanValue)) {
      showValidationMessage("Value should be numeric");
      return false;
    }
    if ((rowData.dataType === 1 || rowData.dataType === 4) && isDollar) {
      showValidationMessage("Value should be a percent");
      return false;
    }
    if (rowData.dataType === 0 && isPercentage) {
      showValidationMessage("Value should be a dollar");
      return false;
    }
    if (rowData.dataType === 3) {
      if (newValue !== "" && !isWholeNumber(newValue)) {
        showValidationMessage("Value should be a positive whole number");
        return false;
      }
    }
    if (
      rowData.dataType === 4 &&
      (isNegativeValue(newValue) || isGreaterThan(100, newValue))
    ) {
      showValidationMessage("Value should be between 0% to 100%");
      return false;
    }
    return true;
  };
  const inputFormatter = (row, props) => {
    let cell = row[props.field];
    const elementId = `id-${generateUUID()}`;
    const placeholder = getPlaceholder(row.dataType);
    if (row.isSection) {
      return "";
    } else {
      let cellDiv;
      cell =
        cell !== "" && cell === undefined
          ? (cell = formatDisplay(row.dataType, cell, row.valueFormat))
          : cell;
      if (cell && typeof cell === "string" && cell.includes("$")) {
        let startsWith;
        let endsWith;
        if (cell.startsWith("(")) {
          startsWith = cell.slice(0, 2);
          endsWith = cell.slice(2);
        } else {
          startsWith = cell.slice(0, 1);
          endsWith = cell.slice(1);
        }
        cellDiv = (
          <div className="form-control accounting-wrapper" id={elementId}>
            <div>{startsWith}</div>
            <div>{endsWith}</div>
          </div>
        );
      } else {
        cellDiv = (
          <div id={elementId} className="form-control">
            {cell}
          </div>
        );
      }
      return cell !== "" ? (
        cellDiv
      ) : (
          <div id={elementId} className="form-control">
            {placeholder}
          </div>
        );
    }
  };
  const isGreaterThan = (constraint, stringValue) => {
    let cleanValue = sanitizeNumber(stringValue);
    let value = parseFloat(cleanValue);
    return value > constraint;
  };
  const isNegativeValue = (value) => {
    return (
      value.indexOf("-") !== -1 ||
      value.indexOf("(") !== -1 ||
      value.indexOf(")") !== -1
    );
  };
  const formatDisplay = (dataType, value, valueFormat) => {
    if (value !== "" && value !== undefined) {
      const isPercentage = value.indexOf("%") !== -1;
      const isNegative = isNegativeValue(value);
      let cleanValue = sanitizeNumber(value);
      let displayValue = parseFloat(cleanValue);
      switch (dataType) {
        case 0:
          return formatDollar(displayValue, isNegative, valueFormat);
        case 1:
          return formatPercent(displayValue, isNegative);
        case 2:
          if (isPercentage) {
            return formatPercent(displayValue, isNegative);
          } else {
            return formatDollar(displayValue, isNegative, valueFormat);
          }
        case 3:
          return value;
        case 4:
          return formatPercent(displayValue, isNegative);
        default:
          return value.replace(/\D/g, "");
      }
    }
  };
  const sanitizeNumber = (value) => {
    return value.replace(/[$%,()-]/g, "");
  };
  const isWholeNumber = (value) => {
    var number = Math.floor(Number(value));
    return number !== Infinity && String(number) === value && number >= 0;
  };
  const formatDollar = (value, isNegative, valueFormat) => {
    let style = { fontSize: "12px" };
    if (valueFormat === "DollarsDoubleTotal") {
      style.textDecoration = "overline";
      style.borderBottom = "3px double black";
      style.fontWeight = "bold";
    }
    if (valueFormat === "DollarsSubtotal") {
      style.textDecoration = "underline overline";
      style.fontWeight = "bold";
    }
    if (isNaN(parseFloat(value))) {
      return "";
    }
    let dollarAmount = value.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,");
    dollarAmount = dollarAmount.replace(".00", "").replace("-", "");
    if (isNegative) {
      if (props.financialDataType === "balancesheet") return `($${dollarAmount})`;
      return <span style={style}>{`(${dollarAmount})`}</span>;
    } else {
      if (props.financialDataType === "balancesheet") return `$${dollarAmount}`;
      return <span style={style}>{`${dollarAmount}`}</span>;
    }
  };
  const formatPercent = (value, isNegative) => {
    if (isNaN(parseFloat(value))) {
      return "";
    }
    const percentAmount = value.toFixed(3).replace(/\d(?=(\d{3})+\.)/g, "$&,");
    if (isNegative) {
      return `(${percentAmount}%)`;
    } else {
      return `${percentAmount}%`;
    }
  };
  const getPlaceholder = (dataType) => {
    switch (dataType) {
      case 0:
        return "$";
      case 1:
        return "%";
      case 2:
        return "$ or %";
      case 3:
        return "";
      case 4:
        return "%";
      default:
        return "$";
    }
  };
  // const clickPrevious = (event) => {
  //   if (data.previousEnabled === true) {
  //     const displayColumns = getDisplayColumns();
  //     const leftColumnIndex = data.columns.indexOf(displayColumns[0]);
  //     const rightColumnIndex = data.columns.indexOf(
  //       displayColumns[displayColumns.length - 1]
  //     );
  //     if (columnInputOffSet < leftColumnIndex) {
  //       data.columns[leftColumnIndex - 1].hidden = false;
  //       data.columns[rightColumnIndex].hidden = true;
  //     }
  //     const previousEnabled = canEnablePrevious(
  //       data.columns[leftColumnIndex - 2]
  //     );
  //     setData({
  //       isLoading: false,
  //       financialData: data.financialData,
  //       historicalYear: data.historicalYear,
  //       numberOfYears: data.numberOfYears,
  //       columns: data.columns,
  //       previousEnabled: previousEnabled,
  //       nextEnabled: true,
  //     });
  //   }
  // };
  // const clickNext = (event) => {
  //   if (data.nextEnabled) {
  //     const displayColumns = getDisplayColumns();
  //     const leftColumnIndex = data.columns.indexOf(displayColumns[0]);
  //     const rightColumnIndex = data.columns.indexOf(
  //       displayColumns[displayColumns.length - 1]
  //     );
  //     if (
  //       data.columns.length > rightColumnIndex &&
  //       data.columns[rightColumnIndex + 1] !== undefined
  //     ) {
  //       data.columns[rightColumnIndex + 1].hidden = false;
  //       data.columns[leftColumnIndex].hidden = true;
  //     }
  //     const nextEnabled = canEnableNext(data.columns[rightColumnIndex + 2]);
  //     setData({
  //       isLoading: false,
  //       financialData: data.financialData,
  //       historicalYear: data.historicalYear,
  //       numberOfYears: data.numberOfYears,
  //       columns: data.columns,
  //       previousEnabled: true,
  //       nextEnabled: nextEnabled,
  //     });
  //   }
  // };
  // const canEnablePrevious = (column) => {
  //   if (column.field === "dataType") {
  //     return false;
  //   }
  //   return true;
  // };
  // const canEnableNext = (column) => {
  //   return column !== undefined;
  // };
  // const getDisplayColumns = () => {
  //   return data.columns.filter((column) => column.hidden === false);
  // };
  const rowObject = (
    name,
    classId,
    groupId,
    sequence,
    dataType,
    valueFormat,
    inputValues,
    dataColumns,
    isSectionRow
  ) => {
    let obj = {};
    const inputCellOffset = 5;
    dataColumns.forEach((column, index) => {
      switch (index) {
        case 0:
          obj[column.field] = name;
          break;
        case 1:
          obj[column.field] = classId;
          break;
        case 2:
          obj[column.field] = groupId;
          break;
        case 3:
          obj[column.field] = sequence;
          break;
        case 4:
          obj[column.field] = dataType;
          break;
        case 5:
          if (props.financialDataType !== "assumptions") {
            let historicValue = inputValues[index - inputCellOffset];
            let value = (props.financialDataType === "balancesheet"
              || props.financialDataType === "incomestatement"
              ? historicValue.inputValue
              : historicValue.projectedValue);
            if (isProjectionChangesReport(props.financialDataType)) {
              let dataField = !isSectionRow
                ? getProjectionBSChangeValue(
                  historicValue.changeInValue,
                  dataType,
                  valueFormat,
                  historicValue.changeInPercent,
                  true
                )
                : "";
              obj[column.field] = dataField;

            }
            else {
              let dataField = !isSectionRow
                ? getHistoricalRowValue(
                  value,
                  dataType,
                  valueFormat
                )
                : "";
              obj[column.field] = dataField;
            }

          } else {
            let projectionInput = inputValues[index - inputCellOffset];
            if (projectionInput !== undefined) {
              if (projectionInput.inputValue == null) {
                obj[column.field] = !isSectionRow
                  ? formatDollar(
                    bankersRound(
                      parseFloat(projectionInput.projectedValue),
                      0,
                      valueFormat
                    ),
                    parseFloat(projectionInput.projectedValue) < 0
                  )
                  : "";
              } else {
                obj[column.field] = !isSectionRow
                  ? projectionInput.inputValue
                  : "";
              }
              if (
                props.financialDataType !== "projections-bs" &&
                props.financialDataType !== "projections-is" &&
                props.financialDataType !== "projections-bsChanges" &&
                props.financialDataType !== "projections-isChanges"
              ) {
                column.editor = editInput;
                column.onBeforeCellEditShow = isCellEditable;
                column.cellEditValidator = validateInput;
                column.onCellEditComplete = cellEditCompleted;
                column.body = inputFormatter;
              }
            }
          }
          break;
        default:
          let value = inputValues[index - inputCellOffset];
          if (value !== undefined) {
            if (value.inputValue == null) {
              if (isProjectionChangesReport(props.financialDataType)) {
                let dataField = !isSectionRow
                  ? getProjectionBSChangeValue(
                    value.changeInValue,
                    dataType,
                    valueFormat,
                    value.changeInPercent,
                    false
                  ) : "";
                obj[column.field] = dataField;
              }
              else {
                obj[column.field] = !isSectionRow
                  ? formatDollar(
                    bankersRound(parseFloat(value.projectedValue)),
                    parseFloat(value.projectedValue) < 0,
                    valueFormat
                  )
                  : "";
              }
            } else {
              if (isProjectionChangesReport(props.financialDataType)) {
                let dataField = !isSectionRow
                  ? getProjectionBSChangeValue(
                    value.changeInValue,
                    dataType,
                    valueFormat,
                    value.changeInPercent,
                    false
                  ) : "";
                obj[column.field] = dataField;
              }
              else {
                obj[column.field] = !isSectionRow ? value.inputValue : "";
              }
            }
            if (
              props.financialDataType !== "projections-bs" &&
              props.financialDataType !== "projections-is" &&
              props.financialDataType !== "projections-bsChanges" &&
              props.financialDataType !== "projections-isChanges"
            ) {
              column.editor = editInput;
              column.onBeforeCellEditShow = isCellEditable;
              column.cellEditValidator = validateInput;
              column.onCellEditComplete = cellEditCompleted;
              column.body = inputFormatter;
            }
          }
      }
      obj["isSection"] = isSectionRow;

      obj["isHeaderBold"] = (valueFormat === "DollarsSubtotal" || valueFormat === "DollarsDoubleTotal")
        && (isProjectionReport(props.financialDataType) || isProjectionChangesReport(props.financialDataType))
    });
    return obj;
  };
  const isCellEditable = (e) => {
    return e.rowData.isSection ? false : true;
  };
  const cellEditCompleted = (e) => {
    let { rowData, newValue, field, originalEvent: event } = e;
    let editCell = document.getElementsByClassName("p-cell-editing")[0];
    if (newValue !== "") {
      rowData[field] = formatDisplay(
        rowData.dataType,
        newValue,
        rowData.valueFormat
      );
    } else {
      rowData[field] = newValue;
    }
    if (editCell) {
      tabNavigate(editCell);
    }
  };
  const tabNavigate = (editCell) => {
    const hidingHistoryColumn = hasHiddendHistoryColumn();
    const editableColumnIndex =
      props.financialDataType === "assumptions" || hidingHistoryColumn ? 1 : 2;
    if (editCell.nextElementSibling) {
      let nextEditCellDiv = editCell.nextElementSibling.children[0];
      clickNextEditCell(nextEditCellDiv);
    } else {
      let nextRow = editCell.parentElement.nextElementSibling;
      let rowIndex = Array.from(
        editCell.parentElement.parentElement.children
      ).indexOf(editCell.parentElement);
      if (nextRow) {
        let nextRowEditCellDiv =
          nextRow.children[editableColumnIndex].children[0];
        if (!nextRowEditCellDiv) {
          //skip section row
          nextRow = editCell.parentElement.parentElement.children[rowIndex + 2];
          nextRowEditCellDiv =
            nextRow.children[editableColumnIndex].children[0];
        }
        clickNextEditCell(nextRowEditCellDiv);
      }
    }
  };
  const clickNextEditCell = (editDiv) => {
    if (editDiv && window.event.relatedTarget !== null) {
      setTimeout(() => {
        editDiv.click();
      }, 100);
    }
  };
  const hasHiddendHistoryColumn = () => {
    return data.previousEnabled && props.financialDataType !== "assumptions";
  };
  const editInput = (options) => {
    return (
      <InputText
        type="text"
        className="p-inputtext-sm"
        value={options.value}
        onChange={(e) => options.editorCallback(e.target.value)}
        onKeyDown={handleEnterKey}
      />
    );
  };
  const handleEnterKey = (e) => {
    //Used to prevent page refresh when enter key is pressed during cell editing
    if (e.key === "Enter") {
      e.preventDefault();
    }
  };
  const getProjectionBSChangeValue = (value, dataType, valueFormat, bsvalue, isHistoric) => {
    let dollarContent;
    let percentageContent;

    // Base styles for dollar and percentage
    const baseDollarStyles = { fontSize: "12px", alignSelf: "flex-end", marginRight: isHistoric ? "50px" : "30px" };
    const basePercentageStyles = { fontSize: "12px", alignSelf: "flex-end", marginRight: "20px" };

    // Conditional styles based on valueFormat
    const conditionalStyles = (valueFormat === "DollarsDoubleTotal")
      ? { textDecoration: "overline", borderBottom: "3px double black", fontWeight: "bold" }
      : (valueFormat === "DollarsSubtotal")
        ? { textDecoration: "underline overline", fontWeight: "bold" }
        : {};

    // Merge base and conditional styles
    const dollarStyle = { ...baseDollarStyles, ...conditionalStyles };
    const percentageStyle = { ...basePercentageStyles, ...conditionalStyles };

    const roundedValue = bankersRound(parseFloat(value), 0);
    if (roundedValue >= 0) {
      dollarContent = (
        <NumericFormat
          value={roundedValue}
          displayType={"text"}
          thousandSeparator={true}
          decimalSeparator="."
          decimalScale={0}
          style={dollarStyle}
        />
      );
    } else {
      dollarContent = (
        // <div>(</div>
        <NumericFormat
          value={String(roundedValue).replace("-", "")}
          displayType={"text"}
          allowNegative={false}
          thousandSeparator={true}
          decimalSeparator="."
          decimalScale={0}
          suffix={")"}
          style={dollarStyle}
        />
      );
    }

    // Percentage check (Handle positive and negative percentage)
    const percentage = bankersRound(parseFloat(bsvalue) * 100, 2);

    if (percentage >= 0) {
      percentageContent = (
        <NumericFormat
          value={percentage}
          displayType={"text"}
          decimalSeparator="."
          decimalScale={2}
          suffix={"%"}
          style={percentageStyle}
        />
      );
    } else {
      percentageContent = (
        <NumericFormat
          value={percentage}
          displayType={"text"}
          allowNegative={false}
          decimalSeparator="."
          decimalScale={2}
          prefix={"("}
          suffix={"%)"}
          style={percentageStyle}
        />
      );
    }

    // Combine both dollar and percentage contents
    const combinedContent = (
      <div className="accounting-wrapper">
        {dollarContent}
        {percentageContent}
      </div>
    );

    return combinedContent;

  }


  const getHistoricalRowValue = (value, dataType, valueFormat, bsvalue) => {
    let style = { fontSize: "12px", alignSelf: "flex-end" };
    if (valueFormat === "DollarsDoubleTotal") {
      style.textDecoration = "overline";
      style.borderBottom = "3px double black";
      style.fontWeight = "bold";
    }
    if (valueFormat === "DollarsSubtotal") {
      style.textDecoration = "underline overline";
      style.fontWeight = "bold";
    }

    if (
      dataType === 0 ||
      dataType === 2 ||
      (dataType === 4 && props.financialDataType === "projections-bs") ||
      (dataType === 1 && props.financialDataType === "projections-is")
    ) {
      const roundedValue = bankersRound(parseFloat(value), 0);
      if (roundedValue >= 0) {
        return (
          <div className="accounting-wrapper">
            <div></div>
            <NumericFormat
              value={roundedValue}
              displayType={"text"}
              thousandSeparator={true}
              decimalSeparator="."
              decimalScale={0}
              style={style}
            />
          </div>
        );
      } else {
        return (
          <div className="accounting-wrapper" >
            <div>(</div>
            <NumericFormat
              value={String(roundedValue).replace("-", "")}
              displayType={"text"}
              allowNegative={false}
              thousandSeparator={true}
              decimalSeparator="."
              decimalScale={0}
              suffix={")"}
              style={style}
            />
          </div>
        );
      }
    } else {
      const percentage = bankersRound(parseFloat(value) * 100, 2);
      if (percentage >= 0) {
        return (
          <NumericFormat
            value={percentage}
            displayType={"text"}
            decimalSeparator="."
            decimalScale={2}
            suffix={"%"}
          />
        );
      } else {
        return (
          <NumericFormat
            value={percentage}
            displayType={"text"}
            allowNegative={false}
            decimalSeparator="."
            decimalScale={2}
            prefix={"("}
            suffix={"%)"}
          />
        );
      }
    }
  };
  const mapInputValues = (inputData) => {
    let inputValues = [];
    let columnYear = data.historicalYear + 1;
    const columnCount = data.numberOfYears - 1;
    for (let i = 0; i <= columnCount; i++) {
      inputValues.push({
        projectionYear: columnYear,
        inputValue: inputData[`values${columnYear.toString()}`],
      });
      columnYear++;
    }
    return inputValues;
  };
  const fetchForecastData = async (props) => {
    try {
      const result = await axios.get(projectionDataEndpoint, apiOptions, props);
      let dataColumns = buildDataColumns(result.data);
      let rows = buildDataRows(
        result.data.financialDataSections,
        dataColumns,
        isProjection,
        result.data.historicalYear
      );
      setData({
        isLoading: false,
        financialData: rows,
        historicalYear: result.data.historicalYear,
        numberOfYears: result.data.numberOfYears,
        columns: dataColumns,
        previousEnabled: data.previousEnabled,
        nextEnabled: data.nextEnabled,
      });
    } catch (error) {
      console.log("Service error " + error);
    }
  };
  const saveSection = async (event) => {
    event.preventDefault();
    setSaving(true);
    let projectionInputs = [];
    data.financialData.forEach((inputData) => {
      if (inputData.groupId != null) {
        projectionInputs.push({
          name: inputData.name,
          classId: inputData.classId,
          groupId: inputData.groupId,
          sequence: inputData.sequence,
          dataType: inputData.dataType,
          inputValues: mapInputValues(inputData),
        });
      }
    });
    await axios
      .post(
        projectionDataEndpoint,
        { projectionLineItemList: projectionInputs },
        apiOptions
      )
      .then(() => {
        setSaving(false);
      })
      .catch((error) => setSaving(false));
  };
  const createAndClickLink = function (blob, extension) {
    const url = window.URL.createObjectURL(new Blob([blob]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", extension);
    document.body.appendChild(link);
    link.click();
  };
  const download = async (event) => {
    setSaving(true);
    let newOptions = { ...apiOptions };
    newOptions["headers"]["content-type"] = "application/json";
    try {
      let response = await axios.post(downloadEndpoint, props.id, newOptions);
      let fileUrl = fileEndpoint + window.username + "/" + response.data;
      let fileEnding = response.data;
      newOptions = { ...apiOptions };
      newOptions["responseType"] = "blob";
      response = await axios.get(fileUrl, newOptions);
      createAndClickLink(response.data, fileEnding);
      setSaving(false);
    } catch (error) {
      setSaving(false);
    }
  };
  const buildDataColumns = (data) => {
    let dataColumns = [];
    let columnYear =
      props.financialDataType !== "assumptions"
        ? (isProjectionReport(props.financialDataType)
          || isProjectionChangesReport(props.financialDataType)
          ? data.historicalYear - 2
          : data.historicalYear)
        : data.historicalYear + 1;
    const columnCount =
      props.financialDataType !== "assumptions"
        ? (isProjectionReport(props.financialDataType)
          || isProjectionChangesReport(props.financialDataType)
          ? data.numberOfYears + 2
          : data.numberOfYears)
        : data.numberOfYears - 1;
    const hiddenIndex =
      props.financialDataType !== "assumptions"
        ? (isProjectionReport(props.financialDataType)
          || isProjectionChangesReport(props.financialDataType)
          ? data.numberOfYears + 3
          : 5)
        : 4;
    dataColumns.push({
      field: "name",
      header: "",
      bodyClassName: "section-group-name align-middle",
      headerClassName: "section-group-name",
    });
    dataColumns.push({ field: "classId", header: "", hidden: true });
    dataColumns.push({ field: "groupId", header: "", hidden: true });
    dataColumns.push({ field: "sequence", header: "", hidden: true });
    dataColumns.push({ field: "dataType", header: "", hidden: true });
    for (let i = 0; i <= columnCount; i++) {
      let hideColumn = false;
      if (i >= hiddenIndex) {
        hideColumn = true;
      }
      dataColumns.push({
        field: `values${columnYear.toString()}`,
        header: columnYear.toString(),
        bodyClassName: "align-middle",
        headerClassName: "section-group-value",
        hidden: hideColumn,
      });
      columnYear++;
    }
    return dataColumns;
  };
  const buildDataRows = (financialDataSections, columns, isProjection, historicalYear) => {
    switch (props.financialDataType) {
      case "balancesheet":
        sectionRowIndexArray = [0, 12, 22];
        break;
      case "incomestatement":
        sectionRowIndexArray = [0, 8, 16, 23];
        break;
      case "projections":
        sectionRowIndexArray = [0, 12, 22];
        break;
      default:
        sectionRowIndexArray = [0];
        break;
    }
    let BSProjections = [];
    let ISProjections = [];
    if (isProjection) {
      if (props.name === "Projected BS" || props.name === "BS Changes") {
        for (const s of financialDataSections) {
          if (
            s.sectionName === "Assets" ||
            s.sectionName === "Liabilities" ||
            s.sectionName === "Capital"
          ) {
            BSProjections.push(s);
          }
        }
      } else {
        for (const s of financialDataSections) {
          if (
            s.sectionName === "Interest Income" ||
            s.sectionName === "Interest Expense" ||
            s.sectionName === "Non-Interest Income" ||
            s.sectionName === "Non-Interest Expense"
          ) {
            ISProjections.push(s);
          }
        }
      }
    }
    if (isProjection && props.name === "Projected BS" || props.name === "BS Changes") {
      financialDataSections = BSProjections;
    } else if (isProjection && props.name === "Projected IS" || props.name === "IS Changes")
      financialDataSections = ISProjections;

    if (isProjection && isProjectionReport(props.financialDataType)) {
      const excludeGroupIds = [570];
      financialDataSections = financialDataSections.map(section => ({
        ...section,
        financialData: section.financialData.filter(item => !excludeGroupIds.includes(item.groupId) &&
          item.classId !== "NIEA")
      }))
    }

    if (props.financialDataType === "balancesheet") {
      const excludeGroupIds = ["100", "330", "902001", "902002", "902005", "902006", "902010", "902015", "902011", "902012"]
      financialDataSections = financialDataSections.map(section =>
        filterFinancialDataByGroupId(section, excludeGroupIds)
      )
    }

    if (props.financialDataType === "incomestatement") {
      const excludeGroupIds = ["902025", "902027", "530", "902028", "902030", "902035", "902020"]
      financialDataSections = financialDataSections.map(section =>
        filterFinancialDataByGroupId(section, excludeGroupIds)
      )
    }

    if (props.financialDataType === "assumptions") {
      const excludeGroupIds = ["901010"];
      financialDataSections = financialDataSections.map(section =>
        filterFinancialDataByGroupId(section, excludeGroupIds)
      )
    }

    let financialDataArrays = financialDataSections.map((section) => {
      let sectionRows = [];
      sectionRows.push(
        rowObject(
          section.sectionName,
          null,
          null,
          null,
          null,
          null,
          section.financialData[0].inputValues,
          columns,
          true
        )
      );
      sectionRows = sectionRows.concat(
        section.financialData.map((data) =>
          rowObject(
            data.name,
            data.classId,
            data.groupId,
            data.sequence,
            data.dataType,
            data.valueFormat,
            data.inputValues,
            columns,
            false
          )
        )
      );
      return sectionRows;
    });
    let rows = [].concat.apply([], financialDataArrays);
    return rows;
  };

  function filterFinancialDataByGroupId(section, excludeGroupIds) {
    return {
      ...section,
      financialData: section.financialData.filter(item => !excludeGroupIds.includes(item.groupId))
    };
  }

  // const footer =
  //   data && data.numberOfYears > showNumberOfYears ? (
  //     <ProjectionFinancialFooter
  //       previous={clickPrevious}
  //       previousEnabled={data.previousEnabled}
  //       next={clickNext}
  //       nextEnabled={data.nextEnabled}
  //     ></ProjectionFinancialFooter>
  //   ) : (
  //       <div className="section-alternate-footer">
  //         <div className="width-0 height-0">
  //           <Button name="tabButton" className="width-0 height-0"></Button>
  //         </div>
  //       </div>
  //     );

  useEffect((props) => {
    fetchForecastData(props);
  }, [props.financialDataType]);


  useEffect(() => {
    if (props.projectionsShown === true
      && (props.financialDataType === "projections-bs"
        || props.financialDataType === "projections-is"
        || isProjectionChangesReport(props.financialDataType))) {
      fetchForecastData(props);
    }
  }, [props.projectionsShown]);

  useEffect(() => {
    if (
      props.projectionsShown === true
      && (props.financialDataType === "projections-bs"
        || props.financialDataType === "projections-is"
        || isProjectionChangesReport(props.financialDataType))) {
      fetchForecastData(props);
    }
    setReloadProjection(false);
  }, [reloadProjection])

  const headerGroup = (
    <ColumnGroup>
      {/* First Row: Actual and Projected Headers */}
      <Row>
        <Column header=" " rowSpan={1} />
        <Column header="Actual" colSpan={3} className="actual-header" />
        <Column header="Projected" colSpan={data.numberOfYears} className="projected-header" />
      </Row>

      {/* Second Row: Year Headers */}
      {data.columns?.length > 0 && (
        <Row>
          {data.columns.map(col => (
            <Column
              className="year-header"
              key={col.field}
              header={col.header}
              hidden={col.hidden}
              editor={col.editor}
              onBeforeCellEditShow={col.onBeforeCellEditShow}
              onCellEditComplete={col.onCellEditComplete}
              cellEditValidator={col.cellEditValidator}
            />
          ))}
        </Row>
      )}

      {/* Third Row: $ and % Headers for Projections */}
      {isProjectionChangesReport(props.financialDataType) && (
        <Row>
          {data.columns.map((col, index) => (
            <Column
              key={`${col.field}-dollar`}
              header={
                index === 0
                  ? col.header // No $ and % for the first column
                  : (
                    <>
                      <span className="dollar">$</span>
                      <span className="percent">%</span>
                    </>
                  )
              }
              hidden={col.hidden}
            />
          ))}
        </Row>
      )}
    </ColumnGroup>
  );

  return (
    <Container>
      {data.isLoading ? (
        <LoadingSpinner isActive={data.isLoading} />
      ) : (
          <div>
            <Form>
              <FormGroup>
                <Row>
                  <Col sm={4} className="text-left">
                    <Link to="/projectionlist">
                      <Button color="secondary" size="sm">
                        <span>
                          <i className="fas fa-arrow-circle-left"></i> Back
                      </span>
                      </Button>
                    </Link>
                  </Col>
                  <Col sm={8} className="text-right">
                    <Button
                      color="primary"
                      size="sm"
                      onClick={saveSection}
                      type="submit"
                      disabled={props.editDisabled || isSaving}
                    >
                      <span>
                        <i className="fas fa-save"></i> Save Projection
                    </span>
                    </Button>
                    <Button
                      color="primary"
                      size="sm"
                      //onClick={generateProjection}
                      onClick={(e) => {
                        e.preventDefault();
                        props.changeActiveTab(3);
                        props.showProjectionTabs();
                        setReloadProjection(true);
                      }}
                      type="submit"
                      disabled={isSaving}
                    >
                      <span>Generate Projection</span>
                    </Button>
                  </Col>
                </Row>
                <div>
                  {data.isLoading ? (
                    <div>Loading ...</div>
                  ) : (
                      <DataTable
                        headerColumnGroup={
                          data.columns?.length > 0 &&
                            (isProjectionReport(props.financialDataType) || isProjectionChangesReport(props.financialDataType))
                            ? headerGroup
                            : undefined
                        }
                        tableClassName={
                          (isProjectionReport(props.financialDataType) ||
                            props.financialDataType === "projections-bsChanges" ||
                            props.financialDataType === "projections-isChanges")
                            ? "section-table financial-statement custom-datatable"
                            : "section-table financial-statement"
                        }
                        dataKey="name"
                        value={data.financialData}
                        showGridlines
                        editMode="cell"
                        rowClassName={rowClasses}
                      // footer={footer}
                      // scrollable
                      // scrollHeight="600px"
                      >
                        {data.columns.map((col, i) => {
                          return (
                            <Column
                              key={col.field}
                              field={col.field}
                              header={col.header}
                              body={col.body}
                              hidden={col.hidden}
                              editor={col.editor}
                              onBeforeCellEditShow={col.onBeforeCellEditShow}
                              onCellEditComplete={col.onCellEditComplete}
                              cellEditValidator={col.cellEditValidator}
                            />
                          );
                        })}
                      </DataTable>
                    )}
                </div>
              </FormGroup>
            </Form>
          </div>
        )}
    </Container>
  );
}
export default ProjectionFinancial;
