import { API_ROOT } from "../../ApiConfig/apiConfig";

export const getUserFdic = (username) => {
  var nameParts = username.split(".");
  return nameParts.length > 2 ? nameParts[2] : nameParts[1];
}

export const formatPhoneNumber = (value) => {
  var cleaned = ('' + value).replace(/\D/g, '');
  var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    return `(${match[1]}) ${match[2]}-${match[3]}`;
  }
  return value;
}

export const isPartner = () => {
  let host = window.location.host;
  let subdomain = '';
  if(!host.includes('localhost')){
      subdomain = host;
      return (subdomain.toLowerCase().includes('fisethos') || (subdomain.toLowerCase().includes('financialview-fi')));
  }
  return false;
}
