import { css, createGlobalStyle } from 'styled-components';
import Fonts from "./Fonts.js";

const style = css`
  body {
    font-family: ${({theme}) => theme.font.main.regular};
    font-size: ${({theme}) => theme.font.size.base};
    font-weight: ${({theme}) => theme.font.weight.normal};
    color: ${({theme}) => theme.colors.neutralDark};
    background-color: ${({theme}) => theme.custom.backgroundColor} !important;

    h1 {
      font-size: ${({theme}) => '100px'};
      font-family: ${({theme}) => theme.font.main.light};
      color: ${({theme}) =>  theme.hTags.h1.color || theme.colors.brandAccent};
      line-height: ${({theme}) => theme.hTags.h1.lineHeight};
    }

    h2 {
      font-size: ${({theme}) => theme.hTags.h2.fontSize};
      font-family: ${({theme}) => theme.font.main.bold};
      color: ${({theme}) =>  theme.hTags.h2.color || theme.colors.neutralMediumDarkest};
      line-height: ${({theme}) => theme.hTags.h2.lineHeight};
    }

    h3 {
      font-family: ${({theme}) => theme.font.main.regular};
      font-size: ${({theme}) => theme.hTags.h3.fontSize};
      color: ${({theme}) =>  theme.hTags.h3.color || theme.colors.neutralDark};
      line-height: ${({theme}) => theme.hTags.h3.lineHeight};
    }

    h4 {
      font-size: ${({theme}) => theme.hTags.h4.fontSize};
      font-family: ${({theme}) => theme.font.main.bold};
      color: ${({theme}) =>  theme.hTags.h4.color || theme.colors.neutralDark};
      line-height: ${({theme}) => theme.hTags.h4.lineHeight};
    }

    h5 {
      font-size: ${({theme}) => theme.hTags.h5.fontSize};
      font-family: ${({theme}) => theme.font.main.bold};
      color: ${({theme}) =>  theme.hTags.h5.color || theme.colors.neutralMediumDarkest};
      line-height: ${({theme}) => theme.hTags.h5.lineHeight};
    }

    h6 {
      font-size: ${({theme}) => theme.hTags.h6.fontSize};
      font-family: ${({theme}) => theme.font.main.bold};
      color: ${({theme}) =>  theme.hTags.h6.color || theme.colors.neutralDark};
      line-height: ${({theme}) => theme.hTags.h6.lineHeight};
    }

    a {
      font-family: ${({theme}) => theme.font.main.bold};
      font-weight: bolder;
      color: ${({theme}) => theme.colors.primary}
    }

    strong {
      color: ${({theme}) =>  theme.colors.neutralDark};
      font-family: ${({theme}) => theme.font.main.bold};
      font-weight: bolder;
    }

    code {
      color: ${({theme}) => theme.colors.primary};
      font-family: 'Courier New', monospace;
    }

    pre {
      margin: 0;
    }

  }

.unify .toggle .toggle-switch-inner::before {
  background-color: ${({theme}) => theme.colors.primary} !important;
}

.unify .form-check .form-check-input[type=checkbox]:checked + .form-check-element::before {
  background-color: ${({theme}) => theme.colors.primary} !important;
}

.nav-link.active::before,
.nav-link:hover::before {
  background-color: ${({theme}) => theme.custom.navLinkUnderline} !important;
}

.unify .nav.nav-tabs .nav-item.tab-user .nav-link.active {
  color: ${({theme}) => theme.custom.navLinkColor} !important;
}
`

const GlobalStyle = createGlobalStyle`
  ${style}
  ${Fonts}
`

export default GlobalStyle