import { API_ROOT } from '../ApiConfig/apiConfig';
/**
 * Intercepts every request and response and if error exists sets the error code
 * @param {*} axios 
 * @param {*} setErrorCode 
 * 
 */

export const errorHandler = (axios, setErrorCode) => {
  axios.interceptors.request.use(request => {
    return request;
  }, err => {
    if (err.request.status !== 401) {
      setErrorCode(err.request.status);
    }
    return Promise.reject(err);
  });

  axios.interceptors.response.use(null, err => {
    let isDuplicateUserUrl = (err.config.url.startsWith(`${API_ROOT.userEndpoint}/api/${window.fdic}/users/`) && err.config.url.endsWith(`.${window.fdic}`));
    if(err.message === 'Network Error'){
      setErrorCode(503);
    }
    else if (err.response.status !== 401) {
      if(!isDuplicateUserUrl){
        setErrorCode(err.response.status);
      }
    }
    return Promise.reject(err);
  });
}