
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleLeft, faAngleRight } from '@fortawesome/free-solid-svg-icons';
import { Button } from 'react-bootstrap';

function ProjectionFinancialFooter (props) {
    return (
        <div className="section-footer">
            <div className="section-footer-button-toolbar">
                <Button data-testid="pj-prev" className="unify-btn secondary-btn btn-xs section-footer-button" onClick={props.previous} disabled={!props.previousEnabled}>
                    <FontAwesomeIcon icon={faAngleLeft} />
                </Button>
                <Button data-testid="pj-next" className="unify-btn secondary-btn btn-xs section-footer-button" onClick={props.next} disabled={!props.nextEnabled}>
                    <FontAwesomeIcon icon={faAngleRight} />
                </Button>
            </div>
        </div>
    );
}
export default ProjectionFinancialFooter;