import React from 'react';
import FedFundsHighChart from "../../Reports/HighChartReport";

export function getTitle() {
    return "Start Page";
}

export default function StartPageTab({id}) {
    return (
        <>
          <FedFundsHighChart></FedFundsHighChart>
        </>
      );
}