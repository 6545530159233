import React from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'unify-react';

function ConfirmModal (props) {
  return (
    <Modal isOpen={props.show} toggle={props.handleClose}>
        <ModalHeader toggle={props.handleClose}>{props.title}</ModalHeader>
        <ModalBody>
        <p>{props.body}</p>
        </ModalBody>
        <ModalFooter>
        <Button color="secondary" onClick={props.handleClose}>
            {props.closeLabel}
        </Button>
        <Button color={props.buttonColor} onClick={props.handleConfirm}>{props.buttonLabel}</Button>
        </ModalFooter>
    </Modal>
  );
}

export default ConfirmModal;