import axios from "axios";
import React, { useState, useEffect } from "react";
import {UnifyButton} from '@deluxe/unify-button';
import { API_ROOT } from "../../ApiConfig/apiConfig";
import "./customReport.css";
import { TreeTable } from "primereact/treetable";
import { Column } from "primereact/column";
import { LoadingSpinner } from "@deluxe/unify-loading-spinner";
import _ from "lodash";
import * as ReportUtils from "./customReportUtilities";

function ReportAccounts({accountParams, groupId, periodSourceHeader, suppressZeros, addFooter, periodMetadata, setShowReport, setShowAccount}) {
  let foundNode = false;
  const apiOptions = { headers: { Authorization: `Bearer ${window.token}` } };
  const [account, setAccount] = useState(null);
  const [nodes, setNodes] = useState(null);
  const [columns, setColumns] = useState(null);
  const [accountLoading, setAccountLoading] = useState(true);
  const [loading, setLoading] = useState(false);

  const loadChildren = async (accountNumber, bankId) => {
    try {
      let result = null;
      if(accountParams){
        result = await axios.get(`${API_ROOT.glServiceEndpoint}/api/report/profitcenters?accountNumber=${accountNumber}&bankId=${bankId}${accountParams}`,apiOptions);
        return result.data;
      };
      return null;
    
    } catch (error) {
      console.error(error);
    }
  };

  const getUpdatedNodes = (childNodes, nodeKey, value, expanded) => {
    let _childNodes = childNodes.map((childNode) => {
      if (!foundNode) {
        if (childNode.key === nodeKey) {
          childNode = value;
          childNode.expanded = expanded;
          foundNode = true;
        } else if (childNode.children && childNode.children.length > 0 && !foundNode) {
          childNode.children = getUpdatedNodes(childNode.children, nodeKey, value, expanded);
        }
      }
      return childNode;
    });
    return _childNodes;
  };

  const loadProfitCenterNode = (children, dataColumns, isGroupView) => {
    let node = {
      id: children.id,
      key: children.id.toString(),
      lineItemNumber: children.lineItemNumber,
      lineItemCategory: children.lineItemCategory,
      lineItemType: children.lineItemType,
      leaf: true,
      data: ReportUtils.buildNodeData(children, dataColumns, isGroupView),
    };
    return node;
  };
  const onExpand = async (event) => {
    if (!event.node.leaf) {
      let lazyNode = _.cloneDeep(event.node);
      let totNode = _.cloneDeep(event.node);
      lazyNode.data = { name: event.node.data.name };
      totNode.id = "tot" + totNode.id;
      totNode.key = "tot" + totNode.key;
      totNode.leaf = true;
      totNode.expanded = false;
      totNode.className = "bd-group-total-row";
      totNode.data.name = "Totals";
      totNode.lineItemCategory = 3;
      if (lazyNode.id && lazyNode.bankId) {
        if(!event.node.children){
          setLoading(true);
          let profitCenterData = await loadChildren(lazyNode.id, lazyNode.bankId);
          lazyNode.children = [];
          let profitCenterChildren = profitCenterData.lineItems[0].children;
          profitCenterChildren.forEach((childData) => {
            if (!childData.suppressZero) {
              lazyNode.children.push(loadProfitCenterNode(childData, columns, false));
            }
          });
        }
        lazyNode.children.push(totNode);
      }
      let _nodes = nodes.map((node) => {
        if (!foundNode) {
          if (node.key === event.node.key) {
            node = lazyNode;
            node.expanded = true;
            foundNode = true;
          } else if (node.children && node.children.length > 0 && !foundNode) {
            node.children = getUpdatedNodes(node.children, event.node.key, lazyNode, true);
          }
        }
        return node;
      });

      foundNode = false;
      setLoading(false);
      setNodes(_nodes);
    }
  };

  const onCollapse = (event) => {
    let collapsedNode = _.cloneDeep(event.node);
    let collapsedNodeName = event.node.data.name;
    let collapsedNodeData = event.node.children[event.node.children.length - 1].data;
    collapsedNodeData.name = collapsedNodeName;
    collapsedNode.data = collapsedNodeData;
    collapsedNode.children.pop();
    let _nodes = nodes.map((node) => {
      if (!foundNode) {
        if (node.key === event.node.key) {
          node = collapsedNode;
          node.expanded = false;
          foundNode = true;
        } else if (node.children && node.children.length > 0 && !foundNode) {
          node.children = getUpdatedNodes(node.children, event.node.key, collapsedNode, false);
        }
      }
      return node;
    });
    foundNode = false;
    setNodes(_nodes);
  };

  const goBack = () => {
    setShowReport(true);
    setShowAccount(false);
    setAccount(null);
  }

  useEffect(() => {
    const fetchAccountData = async () => {
      try {
        const result = await axios.get(`${API_ROOT.glServiceEndpoint}/api/report/accounts?groupId=${groupId}${accountParams}`, apiOptions);
        const accountData = result.data;
        accountData.isGroupView = false;
        accountData.accountHeader = accountData.lineItems[0].lineItemData;
        accountData.accountHeader.periodSourceHeader = periodSourceHeader;
        accountData.suppressZeros = suppressZeros;
        accountData.addFooter = addFooter;
        setAccount(accountData);
        let colsData = ReportUtils.buildColumns(accountData.accountHeader.periodValues, periodMetadata);
        setColumns(colsData);
        let nodesData = ReportUtils.loadNodes(accountData.lineItems, colsData, false, false);
        setNodes(nodesData);
        setAccountLoading(false);
      } catch (error) {
        console.error(error);
      }
    };
    fetchAccountData();
  }, [accountParams]);
  if (account && columns && columns.length > 0 && nodes && nodes.length > 0) {
    return (
      <>
      <div className="report-container">
      <div className="report-title">
          <h1 className="app-title-h1">{account.title}</h1>
          <span className="report-custom-subtitle">{account.subtitle}</span>
        </div>
          <UnifyButton onClick={goBack} type="button" variant="primary">
              <span>
                <i className="fas fa-arrow-circle-left"></i> Back
              </span>
          </UnifyButton>
      </div>
        <div className="card">
          <div className="period-source-header">
            <span>{account.accountHeader.periodSourceHeader}</span>
          </div>
          <TreeTable
            value={nodes}
            lazy
            scrollable
            scrollHeight="65vh"
            loading={loading}
            onExpand={onExpand}
            onCollapse={onCollapse}
            footer={account.suppressZeros && account.addFooter ? ReportUtils.footer : null}
            frozenWidth="320px"
            tableClassName="tableStyle"
          >
            {columns.map((col, i) => (
              <Column
                key={col.field}
                field={col.field}
                header={col.header}
                frozen={col.frozen}
                expander={col.expander}
                align={col.align}
                body={col.body}
                className="bd-table-cell"
                style={{ textAlign: col.align, width: col.width }}
              />
            ))}
          </TreeTable>
        </div>
      </>
    );
  } else {
    return <LoadingSpinner isActive={accountLoading} />;
  }
}

export default ReportAccounts;
