import { css } from "styled-components";
import SourceSansPro_Bold from './Fonts/Source_Sans_Pro/SourceSansPro-Bold.ttf';
import SourceSansPro_SemiBold from './Fonts/Source_Sans_Pro/SourceSansPro-Black.ttf';
import SourceSansPro_Black from './Fonts/Source_Sans_Pro/SourceSansPro-SemiBold.ttf';
import SourceSansPro_Regular from './Fonts/Source_Sans_Pro/SourceSansPro-Regular.ttf';

export const Fonts = css`
  @font-face {
    font-family: "SourceSansPro-Black";
    src: url(${SourceSansPro_Black}) format('truetype');
  }
  @font-face {
    font-family: "SourceSansPro-SemiBold";
    src: url(${SourceSansPro_SemiBold}) format('truetype');
  }
  @font-face {
    font-family: "SourceSansPro-Bold";
    src: url(${SourceSansPro_Bold}) format('truetype');
  }
  @font-face {
    font-family: "SourceSansPro-Regular";
    src: url(${SourceSansPro_Regular}) format('truetype');
  }
`;

export default Fonts