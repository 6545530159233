import React from "react";
import styles from "./tooltip.module.css";


const Tooltip = (props) => {
    return (
      <div className={styles.tooltip}>
          <span className={styles.tooltiptext}>{props.title}</span>
          {props.children}
      </div>
    );
  };
  
  export default Tooltip;
  